import getInstance from './config';

export default class StatisticApi {
  static async getStatisticsDashboard (params) {
    const instance = await getInstance();
    const { data } = await instance.get('/statistic/dashboard', { params });
    return data;
  }

  static async getStatisticsUser (params) {
    const instance = await getInstance();
    const { data } = await instance.get('/statistic/user', { params });
    return data;
  }

  static async getStatisticsPaginated (params) {
    const instance = await getInstance();
    const { data } = await instance.get('/statistic/dashboard', { params });
    return data;
  }

  static async getStatisticDetails (id) {
    const instance = await getInstance();
    const { data } = await instance.get(`/statistic/dashboard/${id}`);
    return data;
  }

  static async update (id, statisticData) {
    const instance = await getInstance();
    const { data } = await instance.put(`/statistic/dashboard/${id}`, statisticData);
    return data;
  }

  static async createStatistic (statisticData) {
    const instance = await getInstance();
    const { data } = await instance.post('/statistic/dashboard', statisticData);
    return data;
  }

  static async remove (id) {
    const instance = await getInstance();
    const { data } = await instance.delete(`/statistic/dashboard/${id}`);
    return data;
  }

  static async getStatisticSelectives () {
    const instance = await getInstance();
    const { data } = await instance.get('/statistic/dashboard/selectives');
    return data;
  }

  static async getUsersForStatisticsPaginated (params) {
    const instance = await getInstance();
    const { data } = await instance.get('/user/statistics', { params });
    return data;
  }

  static async getQuizStats (params) {
    const instance = await getInstance();
    const { data } = await instance.get('/statistic/quiz-stats', { params });
    return data;
  }

  static async getStudentProgres (params) {
    const instance = await getInstance();
    const { data } = await instance.get('/statistic/student-progress-statistics', { params });
    return data;
  }
}
