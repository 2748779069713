import { Col, message, Row } from 'antd'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import AdvancedSelect from '../../shared/AdvancedSelect/AdvancedSelect'
import { I18n } from 'react-redux-i18n'
import { VerticalFiltersPanel } from '../../VerticalFiltersPanel/VerticalFiltersPanel'
import { ContentsListToAssign } from '../ContentsListToAssign/ContentsListToAssign'
import { ContentMediaType, TRAIL_OPTION, TRAIL_OPTION_LABEL } from '../../../app/enum/trails'
import { useDynamicFiltersByType } from '../../../app/hooks/trails/useDynamicFiltersByType'
import { cleanPagination } from '../../../app/utils/pagination'
import QuizApi from '../../../app/api/quiz'
import ContentApi from '../../../app/api/content'
import { VideoLinkSelector } from '../../VideoLinkSelector/VideoLinkSelector'

export const TrailCompositionItem = ({ type, defineItem }) => {
  const trailItemOptionsList = Object.values(TRAIL_OPTION).map(trailItemOption => ({
    id: trailItemOption,
    name: TRAIL_OPTION_LABEL[trailItemOption](),
  }));

  const [trailItemType, setTrailItemType] = useState(type)
  const [appliedFilters, setAppliedFilters] = useState({})
  const [tableData, setTableData] = useState()
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState({});


  const { filters, headerText } = useDynamicFiltersByType(trailItemType);

  const getContentDetailsUrl = useCallback((id) => {
    switch (trailItemType) {
      case TRAIL_OPTION.QUESTION:
        return `${I18n.t('routes.panel.quiz.urlQuestion')}${id}`
      case TRAIL_OPTION.VIDEO_QUESTION:
        return `${I18n.t('routes.panel.quiz.urlQuestion')}${id}`
      case TRAIL_OPTION.AUDIO:
      case TRAIL_OPTION.VIDEO:
      case TRAIL_OPTION.RESUME:
        return `${I18n.t('routes.panel.contentDetails.url')}${id}`
      case TRAIL_OPTION.EXAM:
        return `${I18n.t('routes.panel.quiz.urlQuiz')}${id}`
      default:
        return async () => null
    }
  }, [trailItemType])

  const fetchTableData = useMemo(() => {
    switch (trailItemType) {
      case TRAIL_OPTION.QUESTION:
        return QuizApi.getQuestionsPaginated
      case TRAIL_OPTION.VIDEO_QUESTION:
        return (params) => QuizApi.getQuestionsPaginated({ ...params, fileType: 'video' })
      case TRAIL_OPTION.AUDIO:
        return (params) => ContentApi.getContentsPaginated({ ...params, contentType: ContentMediaType.AUDIO })
      case TRAIL_OPTION.VIDEO:
        return (params) => ContentApi.getContentsPaginated({ ...params, contentType: ContentMediaType.VIDEO })
      case TRAIL_OPTION.RESUME:
        return (params) => ContentApi.getContentsPaginated({ ...params, contentType: ContentMediaType.RESUME })
      case TRAIL_OPTION.EXAM:
        return QuizApi.getQuizzesPaginated
      default:
        return async () => null
    }
  }, [trailItemType]);

  const findData = async (params) => {
    try {
      setLoading(true)
      const data = await fetchTableData(params);
      setTableData(data)
    } catch (err) {
      message('Erro ao buscar dados')
    } finally {
      setLoading(false)
    }
  }

  const handleFilter = async (params) => {
    const filtersToApply = {
      ...params,
      orderBy: 'createdAt',
      isDESC: true,
      ...cleanPagination,
    }
    setAppliedFilters(filtersToApply)
    findData(filtersToApply)
  };

  const handleGetDataByTable = async (params) => {
    const filtersToApply = {
      ...appliedFilters,
      ...params,
    }
    setAppliedFilters(filtersToApply)
    findData(filtersToApply)
  }

  const handleClear = () => {
    handleFilter({})
  }

  useEffect(() => {
    setTableData({ count: 0, rows: [] })
    setAppliedFilters(null)
  }, [trailItemType, type]);

  const onSelectRow = (selectedRowKeys) => {
    if (!tableData?.rows) {
      setSelectedRows({});
      return;
    }

    setSelectedRows((prev) => {
      const newObj = { ...prev };
      const selectedSet = new Set(selectedRowKeys);

      tableData.rows.forEach((tableLine) => {
        if (selectedSet.has(tableLine.id)) {
          newObj[tableLine.id] = tableLine;
        } else {
          delete newObj[tableLine.id];
        }
      });

      return newObj;
    });
  };

  const selectedRowKeys = useMemo(() => Object.values(selectedRows).map(({ id }) => id), [selectedRows])

  const onApplySelection = () => {
    if (selectedRowKeys.length === 0) {
      return
    }

    const data = Object.values(selectedRows);

    const items = data.map(item => ({
      id: item.id,
      type: trailItemType,
      title: item.title || item.description
    }));

    defineItem(items);
  };

  return (
    <div className="trail_composition_item_container">
      <Col>
        <AdvancedSelect
          options={trailItemOptionsList}
          label={I18n.t(
            'routes.panel.knowledgeTrails.create.contents.typeSelect.label'
          )}
          value={trailItemType}
          onChange={setTrailItemType}
        />

        {trailItemType && (
          <>
            {trailItemType === TRAIL_OPTION.YOUTUBE ||
              trailItemType === TRAIL_OPTION.KNOWLEDGE_PREVIEW ? (
              <VideoLinkSelector onSelectVideo={defineItem} videoType={trailItemType} />
            ) : (
              <Row className="select_content_box">
                <VerticalFiltersPanel
                  headerLabel={headerText}
                  fields={filters}
                  onFilter={handleFilter}
                  onClear={handleClear}
                  onApplySelection={onApplySelection}
                />
                <ContentsListToAssign
                  data={tableData}
                  selectedRowKeys={selectedRowKeys}
                  onDetails={(id) =>
                    window.open(getContentDetailsUrl(id), '_blank')?.focus()
                  }
                  getData={handleGetDataByTable}
                  loading={loading}
                  onSelectRow={onSelectRow}
                />
              </Row>
            )}
          </>
        )}
      </Col>
    </div>
  );
}
