import React, { useState } from 'react'

import { Button, Col, Input, message, Row } from 'antd'
import { TRAIL_OPTION } from '../../app/enum/trails';
import { getYoutubeVideoIdByUrl, youtubeBaseUrl, youtubeContentType, youtubeProviderName } from '../../app/utils/youtube';
import { I18n } from 'react-redux-i18n';

const { Search } = Input;

export const VideoLinkSelector = ({ onSelectVideo, videoType = TRAIL_OPTION.YOUTUBE }) => {
  const [ videoSearched, setVideoSearched ] = useState();
  const [ loading, setLoading ] = useState(false)

  const onSearch = async (urlTyped) => {
    if (loading) return;

    setLoading(true)

    try {
      const response = await fetch(`https://noembed.com/embed?dataType=json&url=${urlTyped}`)
      const videoResult = await response.json()


      const { provider_name, title, type, thumbnail_url, url } = videoResult

      if (provider_name === youtubeProviderName && type === youtubeContentType) {
        const videoId = getYoutubeVideoIdByUrl(urlTyped)
        const content = {
          title,
          type: videoType,
          url,
          thumbnailUrl: thumbnail_url,
          videoId,
        }
        
        setVideoSearched(content)
      } else {
        throw new Error();
      }
    } catch (err) {
      message.error(I18n.t('shared.error.not_found_video'));
    } finally {
      setLoading(false)
    }
  };

  const handleUseCurrentVideo = () => {
    onSelectVideo(videoSearched)
  }

  return (
    <Col className='video_link_selector_container'>
      <div
        className='advanced-input'
      >
        <label className='advanced-input__label'>
          <span className='advanced-input__label__inner'>Link do Vídeo</span>
          <Search
            placeholder="Insira o link do vídeo"
            onSearch={onSearch}
            enterButton
            loading={loading}
          />
        </label>
      </div>

      {videoSearched && (
        <Col className='searched_video_container'>
          <iframe width="100%"
            height="450"
            src={`${youtubeBaseUrl}${videoSearched.videoId}`}>
          </iframe>

          <Row className='use_video_container'>
            <Button type='primary'
              onClick={handleUseCurrentVideo}>
              {I18n.t('shared.useVideo')}
            </Button>

            <i className='video_title'>{videoSearched.title}</i>
          </Row>
        </Col>
      )}
    </Col>
  )
}