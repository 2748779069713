import React, { useEffect, useState } from 'react';
import { Button, Collapse, Layout, Progress, Row, Switch, Tooltip } from 'antd';
import { PieChartOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { I18n } from 'react-redux-i18n';
import { useLocation } from '@reach/router';
import { connect } from 'react-redux';

import DataTable from '../../../../../components/shared/DataTable/DataTable';
import {
  FilterSelector,
  LoadingSelectors,
  TrailsSelectores,
  UserSelectors,
} from '../../../../../app/redux/reducers';
import { FilterActions, TrailsActions } from '../../../../../app/redux/actions';
import { useUserAssignmentToTrails } from '../../../../../app/hooks/trails/useUserAssignmentToTrails';
import { useStudentsAssignmentTableData } from '../../../../../app/hooks/trails/useStudentsAssignmentTableData';
import { AssignmentFilterForm } from './AssignmentFilterForm';
import { UsersModalList } from '../../../../../components/UsersModalList/UsersModalList';

const { Content } = Layout;
const { Panel } = Collapse;

export const StudentAssignmentComponent = ({
  loading,
  getUsersPaginated,
  usersPaginated,
  getUsersProgress,
  setStudentUsersOnTrailsFilters,
  usersOnTrailsFilter,
  onNext,
  saveButton,
  onChange,
  defaultStudentsList,

}) => {
  const [ usersProgress, setUsersProgress ] = useState([])
  const location = useLocation()
  const trailId = location.pathname.split('details/')[1]

  const { usersToShowInTable, insertOrRemoveUser, selectedUsers } =
    useUserAssignmentToTrails({
      usersPaginated,
      onChangeUsersList: (list) => onChange('students', list),
      defaultStudentsList,
    });

  const addInfoToUsersInTable = usersToShowInTable.rows.map(user => {
    const userInTrail = usersProgress.find(u => u.userId === user.id)

    if (userInTrail) {
      return {
        ...user,
        progress: userInTrail.progress
      }
    }

    return user
  })

  const { applyAdvancedFilters, cleanAdvancedFilters, handleGetData } =
    useStudentsAssignmentTableData({
      getUsersFn: getUsersPaginated,
      saveFiltersFn: setStudentUsersOnTrailsFilters,
      trailId
    });

  useEffect(() => {
    setUsersProgress(getUsersProgress)
  },[ getUsersProgress ]);

  return (
    <div>
      <Content className='panel__layout__content trail_step_container'>
        <Row align='middle'
          className='step_indicator_container'>
          <h3>
            <UsergroupAddOutlined />
            {I18n.t('routes.panel.knowledgeTrails.create.assignment.header')}
          </h3>
          <UsersModalList
            usersList={selectedUsers}
            deleteItem={insertOrRemoveUser}
          />
        </Row>
      </Content>
      <Content className='panel__layout__content panel__layout__content--advanced-filter'>
        <Collapse className='advanced-filter'>
          <Panel header={I18n.t('shared.advancedFilters.title')}
            key='1'>
            <AssignmentFilterForm
              onSubmit={applyAdvancedFilters}
              defaultFilters={usersOnTrailsFilter}
              reset={cleanAdvancedFilters}
            />
          </Panel>
        </Collapse>
      </Content>
      <Content className='panel__layout__content trail_step_container'>
        <DataTable
          getMethod={handleGetData}
          showSizeChanger
          data={{ count: usersToShowInTable.count, rows: addInfoToUsersInTable}}
          loading={loading > 0}
          pageSizeOptions={[ '10', '20', '50', '100' ]}
          params={usersOnTrailsFilter}
          columns={[
            {
              key: I18n.t(
                'routes.panel.knowledgeTrails.create.assignment.dataTable.columns.name.key',
              ),
              title: I18n.t(
                'routes.panel.knowledgeTrails.create.assignment.dataTable.columns.name.title',
              ),
            },
            {
              key: I18n.t(
                'routes.panel.knowledgeTrails.create.assignment.dataTable.columns.email.key',
              ),
              title: I18n.t(
                'routes.panel.knowledgeTrails.create.assignment.dataTable.columns.email.title',
              ),
            },
            {
              key: I18n.t(
                'routes.panel.knowledgeTrails.create.assignment.dataTable.columns.companies.key',
              ),
              title: I18n.t(
                'routes.panel.knowledgeTrails.create.assignment.dataTable.columns.companies.title',
              ),
              render: (companies) =>
                (companies?.map((company) => company.name) || []).join(', ') ||
                '-',
            },
            {
              key: I18n.t(
                'routes.panel.knowledgeTrails.create.assignment.dataTable.columns.progress.key',
              ),
              title: I18n.t(
                'routes.panel.knowledgeTrails.create.assignment.dataTable.columns.progress.title',
              ),
              render: (value) => {
                let color = '#EF4444';

                if (value && value >= 90) {
                  color = '#22C55E'
                }

                if (value && value >= 10 && value < 90) {
                  color = '#1677ff'
                }

                return (
                  <Progress
                    percent={value}
                    strokeColor={color}
                    size={[ 300, 20 ]}
                  />
                )
              }
            },
            {
              key: I18n.t(
                'routes.panel.knowledgeTrails.create.assignment.dataTable.columns.selected.key',
              ),
              title: I18n.t(
                'routes.panel.knowledgeTrails.create.assignment.dataTable.columns.selected.title',
              ),
              render: (checked, row) => (
                <div className='display_flex justify_content'>
                  <Switch
                    checked={checked}
                    onChange={(isChecked) => {

                      return insertOrRemoveUser(row, isChecked)
                    }}
                  />
                </div>
              ),
            },
            {
              width: '70px',
              title: I18n.t(
                'routes.panel.knowledgeTrails.create.assignment.dataTable.columns.statistics.title',
              ),
              render: (row) => {
                return (
                  <Tooltip
                    title={I18n.t(
                      'routes.panel.knowledgeTrails.create.assignment.dataTable.columns.statistics.title',
                    )}
                    overlayStyle={{ fontSize: 11 }}
                  >
                    <a
                      type='link'
                      href={`${I18n.t('routes.panel.knowledgeTrails.create.assignment.url')}/${trailId}/${row.id}`}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <Button
                        type='link'
                        icon={<PieChartOutlined />}
                      />
                    </a>
                  </Tooltip>
                )
              },
            },
          ]}
        />
        <Row
          justify='end'
          className='buttons_container'
        >
          <Button disabled={!saveButton.canSave}
            onClick={saveButton.onSave}>
            {saveButton.label}
          </Button>
          <Button type='primary'
            onClick={onNext}>
            {I18n.t('routes.panel.knowledgeTrails.create.button.goNext')}
          </Button>
        </Row>
      </Content>
    </div>
  );
};

const mapStateToProps = (state) => ({
  usersPaginated: TrailsSelectores.getUsersPaginated(state),
  loading: LoadingSelectors.getLoading(state),
  getUsersProgress: TrailsSelectores.getUsersProgress(state),
  usersOnTrailsFilter:
    FilterSelector.getTrailsFilter(state).usersOnTrailsFilter,
});

const mapDispatchToProps = (dispatch) => ({
  getUsersPaginated: (parameters) =>
    dispatch(TrailsActions.getUsersPaginated(parameters)),
  setStudentUsersOnTrailsFilters: (params) =>
    dispatch(FilterActions.setStudentUsersOnTrailsFilters(params)),
});

export const StudentAssignment = connect(
  mapStateToProps,
  mapDispatchToProps,
)(StudentAssignmentComponent);
