import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, Col, Layout, message, Modal, Row, Tooltip, Upload } from 'antd';
import {
  DeleteOutlined,
  InfoCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { LazyInput } from '../../../../../components/LazyInput/LazyInput';
import SimpleInput from '../../../../../components/shared/SimpleInput/SimpleInput';
import { getBase64 } from '../../../../../app/utils/imageUtils';

const TrailInformationsComponent = ({
  onNext,
  saveButton,
  onFieldChange,
  defaultValues,
}) => {
  const [ title, setTitle ] = useState('');
  const [ description, setDescription ] = useState('');
  const trailTitleRef = useRef(null);
  const trailDescriptionRef = useRef(null);
  const { Content } = Layout;
  const [ deleteModal, setDeleteModal ] = useState(false);
  const ALLOWED_FORMATS = [ 'image/jpeg', 'image/jpg', 'image/png' ];

  const beforeUpload = (file) => {
    if (!ALLOWED_FORMATS.includes(file.type)) {
      message.error(I18n.t('routes.panel.knowledgeTrails.create.informations.fields.tooltip.upload'));
      return false;
    }
  };

  const handleUploadChange = (info) => {
    if (info && info.file && info.file.originFileObj) {
      getBase64(info.file.originFileObj, (imageBase64) => {
        onFieldChange('iconImage', imageBase64);
      });
    }
  };

  const handleDelete = () => {
    onFieldChange('iconImage', undefined);
    onFieldChange('imageUrl', undefined);
    setDeleteModal(false);
  };

  const handleTitleChange = (e) => {
    const { value } = e.target;
    setTitle(value);
    onFieldChange('title', value);
  };

  const handleDescriptionChange = (e) => {
    const { value } = e.target;
    setDescription(value);
    onFieldChange('description', value);
  };

  useEffect(() => {
    if (defaultValues) {
      setTitle(defaultValues.title || '');
      setDescription(defaultValues.description || '');
    }
  }, [ defaultValues ]);

  return (
    <Content className='panel__layout__content trail_step_container'>
      <Row align='middle'>
        <h3>
          <InfoCircleOutlined />
          {I18n.t('routes.panel.knowledgeTrails.create.informations.header')}
        </h3>
      </Row>

      <Col className='trail_step_content'>
        <Col className='trail_information_panel_container'>
          <h4>
            {I18n.t(
              'routes.panel.knowledgeTrails.create.informations.fields.title',
            )}
          </h4>
          <Row className='trail_information_panel_content'>
            <Col className='title_description_container'>
              <LazyInput
                handleSendValue={onFieldChange}
                renderInput={() => (
                  <SimpleInput
                    label={I18n.t(
                      'routes.panel.knowledgeTrails.create.informations.fields.input.title',
                    )}
                    ref={trailTitleRef}
                    value={title}
                    maxLength={50}
                    tooltip={I18n.t('routes.panel.knowledgeTrails.create.informations.fields.tooltip.title')}
                    onChange={handleTitleChange}
                  />
                )}
              />
              <Alert
                style={{ marginBottom: 15 }}
                message={I18n.t('routes.panel.knowledgeTrails.create.contents.warning.title')}
                description={I18n.t('routes.panel.knowledgeTrails.create.informations.fields.tooltip.title')}
                type="info"
                showIcon
              />
              <LazyInput
                handleSendValue={onFieldChange}
                renderInput={() => (
                  <SimpleInput
                    label={I18n.t(
                      'routes.panel.knowledgeTrails.create.informations.fields.input.description',
                    )}
                    isTextarea
                    ref={trailDescriptionRef}
                    value={description}
                    onChange={handleDescriptionChange}
                    maxLength={150}
                  />
                )}
              />
              <Alert
                style={{ marginBottom: 15 }}
                message={I18n.t('routes.panel.knowledgeTrails.create.contents.warning.title')}
                description={I18n.t('routes.panel.knowledgeTrails.create.informations.fields.tooltip.description')}
                type="info"
                showIcon
              />
            </Col>
            <Col className='uploader_box_container'>
              {defaultValues?.imageUrl || defaultValues?.iconImage ? (
                <div className='preview_box_container'>
                  <img
                    src={defaultValues.imageUrl || defaultValues?.iconImage}
                    alt='Icon'
                    style={{ width: '100%' }}
                  />
                  <div
                    className='delete_preview'
                    onClick={() => setDeleteModal(true)}
                  >
                    <DeleteOutlined />
                  </div>
                </div>
              ) : (
                <Upload
                  className='uploader'
                  showUploadList={false}
                  onChange={handleUploadChange}
                  listType='picture-card'
                  accept=".jpg,.jpeg,.png"
                  beforeUpload={beforeUpload}
                >
                  <button className='upload_button'
                    type='button'>
                    <PlusOutlined />
                    <div>
                      <span>
                        {I18n.t('routes.panel.knowledgeTrails.create.informations.fields.input.upload')}
                        <Tooltip
                          title={I18n.t('routes.panel.knowledgeTrails.create.informations.fields.tooltip.upload')}
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <InfoCircleOutlined style={{ marginLeft: 5, verticalAlign: 3 }} />
                        </Tooltip>
                      </span>
                    </div>
                  </button>
                </Upload>
              )}
              <Alert
                style={{ marginTop: '15px', maxWidth: '400px' }}
                message={I18n.t('routes.panel.knowledgeTrails.create.contents.warning.title')}
                description={I18n.t('routes.panel.knowledgeTrails.create.informations.fields.tooltip.upload')}
                type="info"
                showIcon
              />
            </Col>
          </Row>
        </Col>

        <Row justify='end'
          className='buttons_container'>
          <Button disabled={!saveButton.canSave}
            onClick={saveButton.onSave}>
            {saveButton.label}
          </Button>
          <Button type='primary'
            onClick={onNext}>
            {I18n.t('routes.panel.knowledgeTrails.create.button.goNext')}
          </Button>
        </Row>

        <Modal
          visible={deleteModal}
          onOk={handleDelete}
          onCancel={() => setDeleteModal(false)}
        >
          <p>
            {I18n.t(
              'routes.panel.knowledgeTrails.create.contents.modal.deleteCover',
            )}
          </p>
        </Modal>
      </Col>
    </Content>
  );
};

export const TrailInformations = connect(null)(TrailInformationsComponent);
