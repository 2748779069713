import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import { navigate } from '@reach/router';
import AuthRequests from '../../api/auth';
import AuthService from '../../services/auth';

import { addLoading, removeLoading } from './loading';

import * as UserActions from './user';
import { ProfileType } from '../../enum/profileType';

export const ACTION_AUTH_LOGIN = 'ACTION_AUTH_LOGIN';
export const ACTION_AUTH_LOGOUT = 'ACTION_AUTH_LOGOUT';

export const ACTION_ME = 'ACTION_ME';

export const saveAuthentication = (authData) => ({
  type: ACTION_AUTH_LOGIN,
  auth: authData,
});

export const cleanAuth = () => async (dispatch) => {
  dispatch({
    type: ACTION_AUTH_LOGOUT,
  });
  dispatch(UserActions.cleanUser());
  AuthService.reset();
  AuthService.redirectToLogin();
};

export const refreshToken = (userData) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const payload = await AuthRequests.refreshToken(userData);

    AuthService.create(payload);
    dispatch({
      type: ACTION_AUTH_LOGIN,
      auth: payload,
    });
  } catch (err) {
    if (err.message === 'invalid_refreshToken') {
      dispatch(logout());
    }
    window.location.href = '/';
  }
  finally {
    dispatch(removeLoading());
  }
}

export const authenticate = (userData, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    userData = {
      ...userData,
    };
    const auth = await AuthRequests.auth(userData);
    AuthService.create(auth);
    dispatch({
      type: ACTION_AUTH_LOGIN,
      auth,
    });
    const data = await AuthRequests.me();
    dispatch({
      type: ACTION_ME,
      payload: data,
    });
    if (data.profileType === ProfileType.USER) {
      message.error(I18n.t('routes.login.errors.invalid_credentials'));
      AuthService.reset();
      AuthService.redirectToLogin();
      return undefined;
    }
    if (callback && typeof callback === 'function') {
      callback();
    }
    if ([ProfileType.OPERATOR, ProfileType.ADMIN, ProfileType.MARKETING].includes(data.profileType)) {
      return navigate(I18n.t('routes.panel.users.url'));
    } if (data.profileType === ProfileType.TEACHER) {
      return navigate(I18n.t('routes.panel.quiz.urlQuestion'));
    } if (data.profileType === ProfileType.TRAIL_MANAGER) {
      return navigate(I18n.t('routes.panel.knowledgeTrails.url'));
    } if (data.profileType === ProfileType.UPLOADER) {
      return navigate(I18n.t('routes.panel.contents.url'));
    } if (data.profileType === ProfileType.QUIZER) {
      return navigate(I18n.t('routes.panel.quiz.urlQuiz'));
    } if (data.profileType === ProfileType.KANBAN_MANAGER) {
      return navigate(I18n.t('routes.panel.userRequests.url'));
    }
  } catch (err) {
    message.error(I18n.t(`routes.login.errors.${err.message}`));
  } finally {
    dispatch(removeLoading());
  }
};

export const forgotPassword = (email, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await AuthRequests.forgotPassword({ email });

    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    message.error(I18n.t(`routes.login.errors.${err.message}`));
  } finally {
    dispatch(removeLoading());
  }
};

export const recoveryPassword = ({ email, recoveryToken, password }, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await AuthRequests.recoveryPassword({
      email,
      recoveryToken,
      password,
    });

    navigate(I18n.t('routes.passwordChanged.url'));

    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    message.error(I18n.t(`routes.login.errors.${err.message}`));
  } finally {
    dispatch(removeLoading());
  }
};

export const getMe = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const data = await AuthRequests.me();
    dispatch({
      type: ACTION_ME,
      payload: data,
    });
  } catch (err) {
    if (err.status !== 401) {
      message.error(I18n.t(`routes.login.errors.${err.message}`));
    }
  } finally {
    dispatch(removeLoading());
  }
};

export const logout = () => async (dispatch) => {
  dispatch(addLoading());
  dispatch(cleanAuth());
  dispatch(removeLoading());
};
