import React from 'react';
import {
  Breadcrumb,
  Button,
  Col,
  Collapse,
  DatePicker,
  Divider,
  Layout,
  message,
  Popconfirm,
  Row,
  Switch,
  Tooltip,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import Icon, {
  AppstoreOutlined,
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
  FilePdfOutlined,
  MinusOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Link, navigate } from '@reach/router';
import DataTable from '../../../components/shared/DataTable';
import {
  CategoryActions,
  FilterActions,
  QuizActions,
  UserActions,
} from '../../../app/redux/actions';
import {
  AuthSelectors,
  CategorySelectors,
  FilterSelector,
  LoadingSelectors,
  QuizSelectors,
  UserSelectors,
} from '../../../app/redux/reducers';
import { ReactComponent as QuizIcon } from '../../../app/assets/img/ic_quiz_blue.svg';
import {
  HomeOutlined,
} from '@ant-design/icons';
import AdvancedButton from '../../../components/shared/AdvancedButton';
import AdvancedInput from '../../../components/shared/AdvancedInput';
import AdvancedSelect from '../../../components/shared/AdvancedSelect/AdvancedSelect';
import {
  DifficultyType,
  DifficultyTypeArray,
  isEnableEnum,
  justificationsOption,
} from '../../../app/enum/questionEnum';
import {
  getSubStringTitle,
  removeEmptyField,
} from '../../../app/utils/functionsUtils';
import { onlyNumbers } from '../../../app/utils/string';
import { ProfileType } from '../../../app/enum/profileType';
import moment from 'moment-timezone';
import { cleanPagination } from '../../../app/utils/pagination'
import { CSVLink } from 'react-csv';
import QuizRequest from '../../../app/api/quiz'
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { generateAndDownloadPdf } from '../../../app/utils/pdf/tablePdf';
import { prepareDataToCsv } from '../../../app/utils/csv';
import { showCompanies } from '../../../app/utils/showCompanies';
pdfMake.vfs = pdfFonts.pdfMake.vfs

const { Panel } = Collapse;

class QuizQuestion extends React.Component {
  constructor(props) {
    super(props);
    const { quizFilters } = props;
    const { questionsFilters } = quizFilters;
    this.state = {
      exportingPfdDataLoading: false,
      exportingDataLoading: false,
      csvData: '',
      params: {
        ...cleanPagination,
        ...questionsFilters,
        description: questionsFilters?.description ?? '',
        questionNumber: questionsFilters?.questionNumber ?? '',
        categoriesId: questionsFilters?.categoriesId ?? '',
        isEnabled: questionsFilters?.isEnabled ?? '',
        location: questionsFilters?.location ?? '',
        difficulty: questionsFilters?.difficulty ?? '',
        startCreatedAt: questionsFilters?.startCreatedAt ?? null,
        endCreatedAt: questionsFilters?.endCreatedAt ?? null,
        year: questionsFilters?.year ?? '',
        id: questionsFilters?.id ?? '',
        justification: questionsFilters?.justification ?? null,
        orderBy: 'createdAt',
        isDESC: false,
      },
      userIds: {},
    };
    this.dataTableRef = React.createRef();
    this.csvLink = React.createRef();
  }

  async componentDidMount () {
    const { params } = this.state;
    const { quizFilters } = this.props;
    const { questionsFilters } = quizFilters;
    await this.props.getCategorySelectives();
    await this.getQuestions({
      ...removeEmptyField(params),
      ...questionsFilters,
    });
    this.state.userIds = this.props.questionsPaginated.rows
      .map((item) => item.createBy)
      .join(',');

    await this.props.getUsersAll({
      userIds: this.state.userIds,
    });
  }

  getCreateBy = (id) => {
    const userName = this.props.usersAll?.find((item) => item?.id === id);
    return userName?.name || '';
  };

  getQuestions = async (parameters, isFromDataTable, isReset) => {
    this.setState({
      params: {
        ...this.state.params,
        ...parameters,
      },
    });

    if (!isReset) {
      parameters = {
        ...this.props.quizFilters.questionsFilters,
        ...parameters,
      };
    }

    if (parameters.startCreatedAt && parameters.endCreatedAt) {
      parameters = {
        ...parameters,
        startCreatedAt: moment(parameters.startCreatedAt).toISOString(),
        endCreatedAt: moment(parameters.endCreatedAt).toISOString(),
      };
    }

    if (
      (parameters.startCreatedAt || parameters.endCreatedAt) &&
      parameters.startCreatedAt === parameters.endCreatedAt
    ) {
      return message.error(I18n.t('routes.login.errors.same_date'));
    }

    let isEnabled;

    if (parameters.isEnabled === 2) {
      isEnabled = true;
    } else if (parameters.isEnabled === 1) {
      isEnabled = false;
    }

    await this.props.getQuestions({ ...parameters, isEnabled });
    this.props.setQuizQuestionsFilters(parameters);
  };

  getDifficulty = (num) => {
    switch (num) {
      case DifficultyType.EASY:
        return 'Fácil';
      case DifficultyType.MEDIUM:
        return 'Médio';
      case DifficultyType.HARD:
        return 'Difícil';
      default:
        return 'Fácil';
    }
  };

  applyAdvancedFilters = (event, parameters) => {
    event.preventDefault();

    this.props.setQuizQuestionsFilters({ ...parameters, ...cleanPagination });
    this.getQuestions({ ...parameters, ...cleanPagination }, true, true);
  };

  showCategories = (array, main) => {
    if (main) {
      return (array.map(
        (item) => item.questionCategory.isMain === true && item.title,
      )).filter(singleCategory => typeof singleCategory === 'string');
    }

    if (!main) {
      let res = '';

      for (let i = 0; i < array.length; i++) {
        if (res === '' && array[ i ].questionCategory.isMain === false) {
          res += `${array[ i ].title}`;
        } else if (array[ i ].questionCategory.isMain === false) {
          res += `, ${array[ i ].title}`;
        }
      }

      return res;
    }
  };

  cleanFilter = () => {
    const params = {
      description: '',
      questionNumber: '',
      categoriesId: '',
      isEnabled: '',
      location: '',
      difficulty: '',
      startCreatedAt: null,
      endCreatedAt: null,
      id: '',
      year: '',
      justification: null,
      ...cleanPagination
    };
    this.props.setQuizQuestionsFilters(params);
    this.getQuestions(params, true, true);
  };

  updateQuestion = async (id, data) => {
    const { updateQuestion } = this.props;
    const { params } = this.state;
    let parameters = { ...params };
    parameters = removeEmptyField(parameters);

    if (parameters.isEnabled === 2) {
      parameters.isEnabled = true;
    } else if (parameters.isEnabled === 1) {
      parameters.isEnabled = false;
    }

    try {
      await updateQuestion(id, data);
      await this.getQuestions({ ...parameters });
      message.success(I18n.t('routes.panel.quiz.messagesUpdateQuestion'));
    } catch (e) {
      message.error(I18n.t('routes.panel.quiz.messagesUpdateQuestionFail'));
    }
  };

  csvExport = async (exportAllDataFromApi = false) => {
    this.setState({ exportingDataLoading: true })

    const preparedDataToCsv = await prepareDataToCsv(
      this.props.questionsPaginated?.rows,
      (row) => ({
        order: row.order,
        enumQuestion: getSubStringTitle(row.description, 0, 200),
        category: this.showCategories(row.categories, true),
        categorySec: this.showCategories(row.categories),
        difficulty: this.getDifficulty(row.difficulty),
        year: row.year,
        createdAt: this.transformDate(row.createdAt),
        createBy: this.getCreateBy(row.createBy),
        location: row.location,
        justification: this.isJustificated(row, true),
        isEnabled: row.isEnabled
      }),
      exportAllDataFromApi,
      this.props.questionsPaginated?.count,
      QuizRequest.getQuestionsPaginated,
      this.state.params,
    )

    this.setState({ exportingDataLoading: false })

    if (preparedDataToCsv) {
      this.setState({ csvData: preparedDataToCsv }, () => {
        setTimeout(() => {
          this.csvLink.current.link.click();
        });
      })
    } else {
      message.error(I18n.t('shared.awaitData'))
    }
  };

  transformDate (date) {
    date = moment(date).format('DD/MM/YYYY');
    return date;
  }

  removeQuestion (id) {
    this.props.removeQuestion(id, () => {
      message.success(I18n.t('routes.panel.quiz.sucessRemovedQuestion'));
      this.dataTableRef.current.reset();
    });
  }

  handleChangeDate (value) {
    const { params } = this.state;

    if (value) {
      params.startCreatedAt = value[ 0 ].toDate();
      params.endCreatedAt = value[ 1 ].toDate();
    } else {
      params.startCreatedAt = null;
      params.endCreatedAt = null;
    }

    this.setState({ params });
  }

  changeParams (name, e) {
    const { params } = this.state;
    const paramsAux = params;

    if (name === 'year') {
      e = onlyNumbers(e);
    }

    if (name === 'questionNumber') {
      e = onlyNumbers(e);
    }

    paramsAux[ name ] = e;
    this.setState({ params: paramsAux });
  }

  isJustificated (row, forCsv = false) {
    if (
      row.justification ||
      row.justificationImage ||
      row.justificationVideo ||
      row.justificationAudio
    ) {
      return !forCsv ? <CheckOutlined style={{ color: '#86F11A' }} /> : I18n.t('routes.panel.quiz.duality.yes');
    }

    return !forCsv ? <MinusOutlined style={{ color: '#F11A1A' }} /> : I18n.t('routes.panel.quiz.duality.no');
  }

  async exportPdfData (exportAllDataFromApi = false) {
    this.setState({ exportingPfdDataLoading: true })

    const tableHeadersToPrint = [
      I18n.t('routes.panel.quiz.dataTable.columns.orderQuestion.title'),
      I18n.t('routes.panel.quiz.dataTable.columns.enumQuestion.title'),
      I18n.t('routes.panel.quiz.dataTable.columns.category.title'),
      I18n.t('routes.panel.quiz.dataTable.columns.categorySec.title'),
      I18n.t('routes.panel.quiz.dataTable.columns.difficulty.title'),
      I18n.t('routes.panel.quiz.dataTable.columns.year.title'),
      I18n.t('routes.panel.quiz.dataTable.columns.createdAt.title'),
      I18n.t('routes.panel.quiz.dataTable.columns.createBy.title'),
      I18n.t('routes.panel.quiz.dataTable.columns.location.title'),
      I18n.t('routes.panel.quiz.dataTable.columns.justification.title'),
      I18n.t('routes.panel.quiz.dataTable.columns.isEnabled.title'),
    ]

    const generated = await generateAndDownloadPdf(
      this.props.questionsPaginated.rows,
      tableHeadersToPrint,
      I18n.t('routes.panel.quiz.pageQuestion'),
      7,
      7,
      (row) => ([
        row.order.toString(),
        getSubStringTitle(row.description, 0, 200),
        this.showCategories(row.categories, true).join(' | '),
        this.showCategories(row.categories),
        this.getDifficulty(row.difficulty),
        row.year.toString(),
        this.transformDate(row.createdAt),
        this.getCreateBy(row.createBy),
        row.location,
        this.isJustificated(row, true),
        row.isEnabled.toString() ? I18n.t('routes.panel.quiz.duality.yes') : I18n.t('routes.panel.quiz.duality.no')
      ]),
      exportAllDataFromApi,
      this.props.questionsPaginated?.count,
      QuizRequest.getQuestionsPaginated,
      this.state.params,
      I18n.t('routes.panel.quiz.pageQuestion')
    )

    this.setState({ exportingPfdDataLoading: false })

    if (!generated) {
      message.error(I18n.t('shared.awaitData'))
    }
  }

  render () {
    const { RangePicker } = DatePicker;
    const { Content } = Layout;
    const { params } = this.state;
    const { loading, categorySelectives, me } = this.props;
    return (
      <>
        <Content className='panel__layout__content panel__layout__content--breadcrumb'>
          <Breadcrumb>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${I18n.t('routes.panel.quiz.urlQuestion')}`}>
                <span>{I18n.t('routes.panel.quiz.pageQuestion')}</span>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>
        <Content className='panel__layout__content panel__layout__content--advanced-filter'>
          <Collapse className='advanced-filter'>
            <Panel header={I18n.t('shared.advancedFilters.title')}
              key='1'>
              <form
                onSubmit={(event) =>
                  this.applyAdvancedFilters(event, this.state.params)
                }
              >
                <Row gutter={16}>
                  <Col span={6}>
                    <AdvancedInput
                      value={params.description}
                      onChange={(e) => this.changeParams('description', e)}
                      placeholder={I18n.t('shared.type')}
                      label={I18n.t(
                        'shared.advancedFilters.form.questionDescription',
                      )}
                    />
                  </Col>
                  <Col span={6}>
                    <AdvancedSelect
                      options={categorySelectives}
                      label={I18n.t('shared.advancedFilters.form.category')}
                      value={params.categoriesId}
                      onChange={(e) => this.changeParams('categoriesId', e)}
                    />
                  </Col>
                  <Col span={6}>
                    <AdvancedSelect
                      options={isEnableEnum}
                      label={I18n.t('shared.advancedFilters.form.isEnabled')}
                      value={params.isEnabled}
                      onChange={(e) => this.changeParams('isEnabled', e)}
                    />
                  </Col>
                  <Col span={6}>
                    <AdvancedSelect
                      options={justificationsOption}
                      label={I18n.t('shared.advancedFilters.form.justification')}
                      value={params.justification}
                      onChange={(e) => this.changeParams('justification', e)}
                    />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={6}>
                    <AdvancedInput
                      value={params.location}
                      onChange={(e) => this.changeParams('location', e)}
                      placeholder={I18n.t('shared.type')}
                      label={I18n.t('shared.advancedFilters.form.local')}
                    />
                  </Col>
                  <Col span={6}>
                    <AdvancedSelect
                      options={DifficultyTypeArray}
                      label={I18n.t('shared.advancedFilters.form.difficulty')}
                      value={params.difficulty}
                      onChange={(e) => this.changeParams('difficulty', e)}
                    />
                  </Col>
                  <Col span={6}>
                    <AdvancedSelect
                      options={[
                        {
                          id: true,
                          name: I18n.t('shared.advancedFilters.form.recent'),
                        },
                        {
                          id: false,
                          name: I18n.t('shared.advancedFilters.form.older'),
                        },
                      ]}
                      label={I18n.t('shared.advancedFilters.form.order')}
                      value={params.isDESC}
                      onChange={(e) => this.changeParams('isDESC', e)}
                    />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={6}>
                    <div className='quiz-question__filter__date-picker'>
                      {I18n.t('shared.advancedFilters.form.createdAt')}
                    </div>
                    <RangePicker
                      value={
                        params && params.startCreatedAt && params.endCreatedAt
                          ? [
                            moment(params.startCreatedAt),
                            moment(params.endCreatedAt),
                          ]
                          : []
                      }
                      placeholder={[
                        I18n.t('shared.advancedFilters.form.startDate'),
                        I18n.t('shared.advancedFilters.form.endDate'),
                      ]}
                      format='DD/MM/YYYY'
                      onChange={(val) => this.handleChangeDate(val)}
                    />
                  </Col>
                  <Col span={6}>
                    <AdvancedInput
                      placeholder={I18n.t('shared.type')}
                      label={I18n.t('shared.advancedFilters.form.year')}
                      value={params.year}
                      onChange={(e) => this.changeParams('year', e)}
                    />
                  </Col>
                  <Col span={6}>
                    <AdvancedInput
                      value={params.questionNumber}
                      onChange={(e) => this.changeParams('questionNumber', e)}
                      placeholder={I18n.t('shared.type')}
                      label={I18n.t('shared.advancedFilters.form.questionNumber')}
                    />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col
                    span={24}
                    className='advanced-filter__search-button text-right'
                  >
                    <AdvancedButton
                      type='link'
                      text={I18n.t('shared.advancedFilters.clearButtonText')}
                      onClick={() => this.cleanFilter()}
                    />
                    <AdvancedButton
                      htmlType='submit'
                      text={I18n.t('shared.advancedFilters.filterButtonText')}
                      icon={<SearchOutlined />}
                    />
                  </Col>
                </Row>
              </form>
            </Panel>
          </Collapse>
        </Content>
        <Content className='panel__layout__content'>
          <div className='users'>
            <Row className='d-flex justify-content-between'>
              <Col>
                <h3>
                  <span className='panel__layout__content__title__value__icon'>
                    <Icon component={QuizIcon} />
                  </span>
                  {I18n.t('routes.panel.quiz.pageQuestion')}
                </h3>
              </Col>
              <Col className='text-right'>
                {([
                  ProfileType.ADMIN,
                ].includes(me?.profileType)) && (
                    <>
                      <AdvancedButton
                        icon={<AppstoreOutlined />}
                        text={I18n.t('shared.exportCsvButtonText')}
                        onClick={() => this.csvExport()}
                        disabled={loading > 0}
                      />
                      <Divider type='vertical' />

                      <AdvancedButton
                        icon={<AppstoreOutlined />}
                        loading={this.state.exportingDataLoading}
                        disabled={this.state.exportingDataLoading || loading > 0}
                        text={I18n.t('shared.exportAllCsvButtonText')}
                        onClick={() => this.csvExport(true)}
                      />
                      <CSVLink
                        data={this.state.csvData}
                        filename={I18n.t('routes.panel.userDetails.csvFileName')}
                        ref={this.csvLink}
                        style={{ display: 'none' }}
                      >
                        {I18n.t('shared.exportCsvButtonText')}
                      </CSVLink>

                      <Divider type='vertical' />

                      <AdvancedButton
                        icon={<FilePdfOutlined />}
                        text={I18n.t('shared.exportPdfButton')}
                        onClick={() => this.exportPdfData()}
                        disabled={loading > 0}
                      />

                      <Divider type='vertical' />

                      <AdvancedButton
                        icon={<FilePdfOutlined />}
                        loading={this.state.exportingPfdDataLoading}
                        disabled={this.state.exportingPfdDataLoading || loading > 0}
                        text={I18n.t('shared.exportAllPdfButton')}
                        onClick={() => this.exportPdfData(true)}
                      />
                    </>
                  )}


                <Divider type='vertical' />
                <AdvancedButton
                  text={I18n.t('routes.panel.quiz.newQuestion')}
                  href={`${I18n.t('routes.panel.quiz.urlQuestion')}add`}
                />
                <Divider type='vertical' />

              </Col>
            </Row>
            <Divider />
            <div className='quizQuestionList'>
              <DataTable
                dontShowIndex
                getMethod={(params) => this.getQuestions(params, true)}
                data={this.props.questionsPaginated}
                ref={this.dataTableRef}
                loading={loading > 0}
                showSizeChanger
                params={params}
                columns={[
                  {
                    key: I18n.t(
                      'routes.panel.quiz.dataTable.columns.orderQuestion.key',
                    ),
                    title: I18n.t(
                      'routes.panel.quiz.dataTable.columns.orderQuestion.title',
                    ),
                    render: (value) => <div className='order-field'>{value}</div>,
                  },
                  {
                    key: I18n.t(
                      'routes.panel.quiz.dataTable.columns.enumQuestion.key',
                    ),
                    title: I18n.t(
                      'routes.panel.quiz.dataTable.columns.enumQuestion.title',
                    ),
                    render: (value, row) => (
                      <div
                        className='column_description question_title_field'
                        onClick={() =>
                          navigate(
                            `${I18n.t('routes.panel.quiz.urlQuestion')}${row.id
                            }`,
                          )
                        }
                      >
                        {getSubStringTitle(value, 0, 200)}
                      </div>
                    ),
                  },
                  {
                    key: I18n.t(
                      'routes.panel.quiz.dataTable.columns.category.key',
                    ),
                    title: I18n.t(
                      'routes.panel.quiz.dataTable.columns.category.title',
                    ),
                    align: 'center',
                    render: (value) => this.showCategories(value, true),
                  },
                  {
                    key: I18n.t(
                      'routes.panel.quiz.dataTable.columns.categorySec.key',
                    ),
                    title: I18n.t(
                      'routes.panel.quiz.dataTable.columns.categorySec.title',
                    ),
                    align: 'center',
                    render: (value) => (
                      <div className="question_category_field">
                        {this.showCategories(value)}
                      </div>
                    )
                  },
                  {
                    width: '85px',
                    key: I18n.t(
                      'routes.panel.quiz.dataTable.columns.difficulty.key',
                    ),
                    title: I18n.t(
                      'routes.panel.quiz.dataTable.columns.difficulty.title',
                    ),
                    render: (value) => (
                      <div className='display_flex justify_content'>
                        {this.getDifficulty(value)}
                      </div>
                    ),
                  },
                  {
                    key: I18n.t('routes.panel.quiz.dataTable.columns.year.key'),
                    title: I18n.t(
                      'routes.panel.quiz.dataTable.columns.year.title',
                    ),
                    render: (value) => value,
                  },
                  {
                    align: 'center',
                    key: I18n.t(
                      'routes.panel.quiz.dataTable.columns.createdAt.key',
                    ),
                    title: I18n.t(
                      'routes.panel.quiz.dataTable.columns.createdAt.title',
                    ),
                    render: (value) => (
                      <div className='display_flex justify_content'>
                        {this.transformDate(value)}
                      </div>
                    ),
                  },
                  {
                    key: I18n.t(
                      'routes.panel.quiz.dataTable.columns.createBy.key',
                    ),
                    title: I18n.t(
                      'routes.panel.quiz.dataTable.columns.createBy.title',
                    ),
                    render: (value) => this.getCreateBy(value),
                  },
                  {
                    width: '10px',
                    key: I18n.t(
                      'routes.panel.quiz.dataTable.columns.location.key',
                    ),
                    title: I18n.t(
                      'routes.panel.quiz.dataTable.columns.location.title',
                    ),
                    align: 'center',
                    render: (value) => (
                      <div className='column_location'>{value}</div>
                    ),
                  },
                  {
                    key: I18n.t(
                      'routes.panel.quiz.dataTable.columns.companies.key',
                    ),
                    title: I18n.t(
                      'routes.panel.quiz.dataTable.columns.companies.title',
                    ),
                    align: 'center',
                    render: (value) => (
                      <div className="question_category_field">
                        {showCompanies(value)}
                      </div>
                    )
                  },
                  {
                    width: '10px',
                    key: I18n.t(
                      'routes.panel.quiz.dataTable.columns.justification.key',
                    ),
                    title: I18n.t(
                      'routes.panel.quiz.dataTable.columns.justification.title',
                    ),
                    align: 'center',
                    render: (value, row) => this.isJustificated(row),
                  },
                  {
                    width: '85px',
                    key: I18n.t(
                      'routes.panel.quiz.dataTable.columns.isEnabled.key',
                    ),
                    title: I18n.t(
                      'routes.panel.quiz.dataTable.columns.isEnabled.title',
                    ),
                    render: (value, row) => (
                      <div className='display_flex justify_content'>
                        <Switch
                          disabled={
                            this.props.me.profileType === ProfileType.QUIZER
                          }
                          checked={value}
                          onChange={() =>
                            this.updateQuestion(row.id, { isEnabled: !value })
                          }
                        />
                      </div>
                    ),
                  },
                  {
                    key: I18n.t(
                      'routes.panel.users.dataTable.columns.actions.key',
                    ),
                    title: '',
                    render: (id) => (
                      <div className='dataTable__item--right'>
                        {this.props.me?.profileType !== ProfileType.OPERATOR && (
                          <>
                            <Tooltip
                              title={I18n.t(
                                'routes.panel.quiz.dataTable.columns.actions.goToEditText',
                              )}
                              overlayStyle={{ fontSize: 11 }}
                            >
                              <Button
                                type='link'
                                icon={<EditOutlined />}
                                onClick={() =>
                                  navigate(
                                    `${I18n.t(
                                      'routes.panel.quiz.urlQuestion',
                                    )}${id}`,
                                  )
                                }
                              />
                            </Tooltip>
                            {([ ProfileType.ADMIN, ProfileType.TEACHER ].includes(this.props.me?.profileType)) && (
                              <Popconfirm
                                placement='left'
                                title={I18n.t('shared.confirmTitle')}
                                onConfirm={() => this.removeQuestion(id)}
                                okText={I18n.t('shared.yes')}
                                cancelText={I18n.t('shared.no')}
                              >
                                <Tooltip
                                  title={I18n.t(
                                    'routes.panel.quiz.dataTable.columns.actions.removeText',
                                  )}
                                  overlayStyle={{ fontSize: 11 }}
                                >
                                  <Button type='link'
                                    icon={<DeleteOutlined />} />
                                </Tooltip>
                              </Popconfirm>
                            )}
                          </>
                        )}
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </Content>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  questionsPaginated: QuizSelectors.getQuestionsPaginated(state),
  categorySelectives: CategorySelectors.getCategorySelectives(state),
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
  quizFilters: FilterSelector.getQuizFilters(state),
  userDetails: UserSelectors.getUserDetails(state),
  usersPaginated: UserSelectors.getUsersPaginated(state),
  usersAll: UserSelectors.getUserAll(state),
});

const mapDispatchToProps = (dispatch) => ({
  getQuestions: (params) => dispatch(QuizActions.getQuestions(params)),
  removeQuestion: (id, callback) =>
    dispatch(QuizActions.removeQuestion(id, callback)),
  getCategorySelectives: () =>
    dispatch(CategoryActions.getCategorySelectives()),
  updateQuestion: (id, question, callback) =>
    dispatch(QuizActions.updateQuestion(id, question, callback)),
  exportQuestions: (callback) => dispatch(QuizActions.exportQuestion(callback)),
  setQuizQuestionsFilters: (params) =>
    dispatch(FilterActions.setQuizQuestionsFilters(params)),
  getUsersPaginated: (parameters, me) =>
    dispatch(UserActions.getUsersPaginated(parameters, me)),
  getUsersAll: (params) => dispatch(UserActions.getAllUser(params)),
});
export default connect(mapStateToProps, mapDispatchToProps)(QuizQuestion);
