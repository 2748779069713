import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import {
  addLoading,
  removeLoading,
} from './loading';
import TrailsRequests from '../../api/trails';
import moment from 'moment-timezone';
import { ProfileType } from '../../enum/profileType';

export const ACTION_SAVE_TRAILS_PAGINATED = 'ACTION_SAVE_TRAILS_PAGINATED'
export const ACTION_SAVE_TRAIL_DETAILS = 'ACTION_SAVE_TRAIL_DETAILS'
export const ACTION_SAVE_TRAIL_SELECTIVES = 'ACTION_SAVE_COURSE_SELECTIVES';
export const ACTION_SAVE_TRAIL_SEARCH = 'ACTION_SAVE_COURSE_SEARCH';
export const ACTION_SAVE_TRAIL_GRAPH = 'ACTION_SAVE_COURSE_GRAPH';
export const ACTION_SAVE_TRAIL_STATISTICS = 'ACTION_SAVE_TRAIL_STATISTICS';
export const ACTION_SAVE_MODULES_SEARCH = 'ACTION_SAVE_SEARCH_MODULES';
export const ACTION_SAVE_USERS_PROGRESS = 'ACTION_SAVE_USERS_PROGRESS';
export const ACTION_SAVE_USERS_PAGINATED = 'ACTION_SAVE_USERS_PAGINATED';

export const getTrailsPaginated = (parameters) => async (dispatch) => {
  let trailsPaginated;
  dispatch(addLoading());
  parameters = {
    ...parameters,
    limit: (parameters && parameters.pageSize) || 10,
    offset: (parameters && parameters.offset) || 0,
    orderBy: 'createdAt',
    isDESC: true,
  };

  try {
    trailsPaginated = await TrailsRequests.getTrailsPaginated(parameters);

    dispatch({
      type: ACTION_SAVE_TRAILS_PAGINATED,
      payload: trailsPaginated,
    });
  } catch (err) {
    message.error(I18n.t('routes.panel.courses.errors.generic'));
  } finally {
    dispatch(removeLoading());
  }

  return trailsPaginated;
};

export const findTrailById = (id) => async (dispatch) => {
  let trailResult;
  dispatch(addLoading());

  try {
    trailResult = await TrailsRequests.findTrailById(id);

    if (trailResult.startAt) {
      trailResult.startAt = moment(trailResult.startAt)
    }

    dispatch({
      type: ACTION_SAVE_TRAIL_SEARCH,
      payload: trailResult,
    });
  } catch (err) {
    message.error(I18n.t('routes.panel.courses.errors.generic'));
  } finally {
    dispatch(removeLoading());
  }

  return trailResult;
};

export const getTrailDetails = (id) => async (dispatch) => {
  dispatch(addLoading());

  try {
    let data = null;

    if (id) {
      data = await TrailsRequests.getTrailDetails(id);
    }

    dispatch({
      type: ACTION_SAVE_TRAIL_DETAILS,
      payload: data,
    });
  } catch (err) {
    throw new Error(err);
  } finally {
    dispatch(removeLoading());
  }
};

export const removeTrail = (id, callback) => async (dispatch) => {
  dispatch(addLoading());

  try {
    await TrailsRequests.deleteTrailById(id);
    callback();
  } catch (err) {
    message.error(I18n.t('routes.panel.knowledgeTrails.errors.generic'));
  } finally {
    dispatch(removeLoading());
  }
};

export const getUsersProgressInTrail = (id) => async (dispatch) => {
  let usersProgress;
  dispatch(addLoading());

  try {
    let usersProgress = []

    if (id) {
      usersProgress = await TrailsRequests.getUsersProgress(id);
    }

    dispatch({
      type: ACTION_SAVE_USERS_PROGRESS,
      payload: usersProgress,
    });
  } catch (err) {
    message.error(I18n.t('routes.panel.courses.errors.generic'));
  } finally {
    dispatch(removeLoading());
  }

  return usersProgress;
};

export const getTrailStatisticsByStudent = (trailId, userId) => async (dispatch) => {
  let statistics;
  dispatch(addLoading());

  try {
    if (trailId && userId) {
      statistics = await TrailsRequests.getTrailStatisticsByUserId(trailId, userId);
    }

    dispatch({
      type: ACTION_SAVE_TRAIL_STATISTICS,
      payload: statistics,
    });
  } catch (err) {
    message.error(I18n.t('routes.panel.courses.errors.generic'));
  } finally {
    dispatch(removeLoading());
  }

  return statistics;
};

export const getUsersPaginated =
  (parameters, clear) => async (dispatch) => {
    dispatch(addLoading());
    parameters = {
      ...parameters,
      limit: (parameters && parameters.pageSize) || 10,
      offset: (parameters && parameters.offset) || 0,
      orderBy: 'createdAt',
      isDESC: true,
    };

    if (parameters.profileType) {
      parameters = {
        ...parameters,
        profileType: parameters.profileType,
      };
    } else if (parameters.profileTypeList) {
      parameters = {
        ...parameters,
        profileTypeList: parameters.profileTypeList,
      };
    }

    try {
      const usersPaginated = await TrailsRequests.getUsersPaginated(parameters);
      dispatch({
        type: ACTION_SAVE_USERS_PAGINATED,
        payload: clear ? { rows: [], count: 0 } : usersPaginated,
      });
    } catch (err) {
      message.error(I18n.t('routes.panel.statistics.errors.allUser'));
    } finally {
      dispatch(removeLoading());
    }
  };
