export const ACTION_STATISTICS_MAIN_FILTERS = 'ACTION_STATISTICS_MAIN_FILTERS';

export const ACTION_USER_STUDENTS_FILTERS = 'ACTION_USER_STUDENTS_FILTERS';
export const ACTION_USER_ADMINS_FILTERS = 'ACTION_USER_ADMINS_FILTERS';

export const ACTION_COURSES_STATISTICS_FILTERS =
  'ACTION_COURSES_STATISTICS_FILTERS';
export const ACTION_COURSES_CHART_FILTERS = 'ACTION_COURSES_CHART_FILTERS';
export const ACTION_COURSES_MODULES_FILTERS = 'ACTION_COURSES_MODULES_FILTERS';
export const ACTION_COURSES_CONTENTS_FILTERS =
  'ACTION_COURSES_CONTENTS_FILTERS';

export const ACTION_QUIZ_SIMULATION_FILTERS = 'ACTION_QUIZ_SIMULATION_FILTERS';
export const ACTION_QUIZ_QUESTIONS_FILTERS = 'ACTION_QUIZ_QUESTIONS_FILTERS';

export const ACTION_NOTIFICATION_FILTERS = 'ACTION_NOTIFICATION_FILTERS';

export const ACTION_MESSAGES_DIRECT_FILTERS = 'ACTION_MESSAGES_DIRECT_FILTERS';
export const ACTION_MESSAGES_CONTENT_FILTERS =
  'ACTION_MESSAGES_CONTENT_FILTERS';
export const ACTION_MESSAGES_QUESTION_DATABASE_FILTERS = 'ACTION_MESSAGES_QUESTION_DATABASE_FILTERS';

export const ACTION_SETTINGS_CATEGORIES_FILTERS = 'ACTION_SETTINGS_CATEGORIES_FILTERS'
export const ACTION_SETTINGS_HOSPITAL_FILTERS = 'ACTION_SETTINGS_HOSPITAL_FILTERS'
export const ACTION_SETTINGS_COMPANIES_FILTERS = 'ACTION_SETTINGS_COMPANIES_FILTERS'

export const ACTION_TRAILS_FILTERS = 'ACTION_TRAILS_FILTERS';

export const ACTION_QUIZ_REPORTS_FILTERS = 'ACTION_QUIZ_REPORTS_FILTERS';
export const ACTION_STUDENTS_PROGRESS_REPORTS_FILTERS = 'ACTION_STUDENTS_PROGRESS_REPORTS_FILTERS'

export const ACTION_USER_STUDENTS_ON_TRAILS_FILTERS = 'ACTION_USER_STUDENTS_ON_TRAILS_FILTERS'

export const setStatisticsMainFilters = (filters) => ({
  type: ACTION_STATISTICS_MAIN_FILTERS,
  payload: filters,
});

export const setUserStudentsFilters = (filters) => ({
  type: ACTION_USER_STUDENTS_FILTERS,
  payload: filters,
});

export const setStudentUsersOnTrailsFilters = (filters) => ({
  type: ACTION_USER_STUDENTS_ON_TRAILS_FILTERS,
  payload: filters,
});

export const setUserAdminsFilters = (filters) => ({
  type: ACTION_USER_ADMINS_FILTERS,
  payload: filters,
});

export const setCoursesListFilters = (filters) => ({
  type: ACTION_COURSES_STATISTICS_FILTERS,
  payload: filters,
});

export const setCoursesChartFilters = (filters) => ({
  type: ACTION_COURSES_CHART_FILTERS,
  payload: filters,
});

export const setCoursesModulesFilters = (filters) => ({
  type: ACTION_COURSES_MODULES_FILTERS,
  payload: filters,
});

export const setCoursesContentsFilters = (filters) => ({
  type: ACTION_COURSES_CONTENTS_FILTERS,
  payload: filters,
});

export const setQuizSimulationFilters = (filters) => ({
  type: ACTION_QUIZ_SIMULATION_FILTERS,
  payload: filters,
});

export const setQuizQuestionsFilters = (filters) => ({
  type: ACTION_QUIZ_QUESTIONS_FILTERS,
  payload: filters,
});

export const setNotificationFilters = (filters) => ({
  type: ACTION_NOTIFICATION_FILTERS,
  payload: filters,
});

export const setMessageDirectFilters = (filters) => ({
  type: ACTION_MESSAGES_DIRECT_FILTERS,
  payload: filters,
});

export const setMessageQuestionDatabaseFilters = (filters) => ({
  type: ACTION_MESSAGES_QUESTION_DATABASE_FILTERS,
  payload: filters,
});

export const setMessageContentFilters = (filters) => ({
  type: ACTION_MESSAGES_CONTENT_FILTERS,
  payload: filters,
});

export const setCategoriesFilters = (filters) => ({
  type: ACTION_SETTINGS_CATEGORIES_FILTERS,
  payload: filters,
});

export const setHospitalsFilters = (filters) => ({
  type: ACTION_SETTINGS_HOSPITAL_FILTERS,
  payload: filters,
});

export const setCompaniesFilters = (filters) => ({
  type: ACTION_SETTINGS_COMPANIES_FILTERS,
  payload: filters,
});

export const setTrailsFilters = (filters) => ({
  type: ACTION_TRAILS_FILTERS,
  payload: filters,
});

export const setQuizReportsFilters = (filters) => ({
  type: ACTION_QUIZ_REPORTS_FILTERS,
  payload: filters,
});

export const setStudentProgresFilters = (filters) => ({
  type: ACTION_STUDENTS_PROGRESS_REPORTS_FILTERS,
  payload: filters,
});