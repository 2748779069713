import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import {
  addLoading,
  removeLoading,
} from './loading';
import StatisticRequests from '../../api/statistic';

export const ACTION_SAVE_STATISTIC_SELECTIVES = 'ACTION_SAVE_STATISTIC_SELECTIVES';
export const ACTION_SAVE_STATISTICS_PAGINATED = 'ACTION_SAVE_STATISTICS_PAGINATED';
export const ACTION_SAVE_STATISTICS_DASHBOARD = 'ACTION_SAVE_STATISTICS_DASHBOARD';
export const ACTION_SAVE_STATISTICS_USER = 'ACTION_SAVE_STATISTICS_USER';
export const ACTION_SAVE_STATISTIC_DETAILS = 'ACTION_SAVE_STATISTIC_DETAILS';
export const ACTION_SAVE_USER_FOR_STATISTICS = 'ACTION_SAVE_USER_FOR_STATISTICS'
export const ACTION_SAVE_QUIZ_STATS = 'ACTION_SAVE_QUIZ_STATS';
export const ACTION_SAVE_STUDENTS_PROGRESS = 'ACTION_SAVE_STUDENTS_PROGRESS'

export const ACTION_CLEAN_STATISTIC = 'CLEAN_STATISTIC';

export const cleanStatistic = () => ({
  type: ACTION_CLEAN_STATISTIC,
  statistic: null,
});

export const getStatisticsDashboard = (parameters) => async (dispatch) => {
  dispatch(addLoading());

  try {
    const statisticsPaginated = await StatisticRequests.getStatisticsDashboard(parameters);
    dispatch({
      type: ACTION_SAVE_STATISTICS_DASHBOARD,
      payload: statisticsPaginated,
    });
  } catch (err) {
    //
  } finally {
    dispatch(removeLoading());
  }
};

export const getStatisticsUser = (parameters) => async (dispatch) => {
  dispatch(addLoading());

  try {
    const statistic = await StatisticRequests.getStatisticsUser(parameters);
    dispatch({
      type: ACTION_SAVE_STATISTICS_USER,
      payload: statistic,
    });
  } catch (err) {
    //
  } finally {
    dispatch(removeLoading());
  }
};

export const getStatisticsPaginated = (parameters) => async (dispatch) => {
  dispatch(addLoading());
  parameters = {
    ...parameters,
    limit: (parameters && parameters.pageSize) || 10,
    offset: (parameters && parameters.offset) || 0,
    orderBy: 'createdAt',
    isDESC: true,
  };

  try {
    const statisticsPaginated = await StatisticRequests.getStatisticsPaginated(parameters);
    dispatch({
      type: ACTION_SAVE_STATISTICS_PAGINATED,
      payload: statisticsPaginated,
    });
  } catch (err) {
    //
  } finally {
    dispatch(removeLoading());
  }
};

export const getStatisticSelectives = () => async (dispatch) => {
  try {
    const statisticSelectives = await StatisticRequests.getStatisticSelectives();
    dispatch({
      type: ACTION_SAVE_STATISTIC_SELECTIVES,
      payload: statisticSelectives,
    });
  } catch (err) {
    //
  }
};

export const getStatisticDetails = (id) => async (dispatch) => {
  dispatch(addLoading());

  try {
    let data = null;

    if (id) {
      data = await StatisticRequests.getStatisticDetails(id);
    }

    dispatch({
      type: ACTION_SAVE_STATISTIC_DETAILS,
      payload: data,
    });
  } catch (err) {
    //
  } finally {
    dispatch(removeLoading());
  }
};

export const updateStatistic = (id, data, callback) => async (dispatch) => {
  dispatch(addLoading());

  try {
    await StatisticRequests.update(id, data);

    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    message.error(I18n.t(`routes.panel.statisticDetails.messages.errors.${err.message}`));
    throw new Error();
  } finally {
    dispatch(removeLoading());
  }
};


export const createStatistic = (data, callback) => async (dispatch) => {
  dispatch(addLoading());

  try {
    data = {
      ...data,
    };
    await StatisticRequests.createStatistic(data);

    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    message.error(I18n.t(`routes.panel.statisticDetails.messages.errors.${err.message}`));
  } finally {
    dispatch(removeLoading());
  }
};

export const removeStatistic = (id, callback) => async (dispatch) => {
  dispatch(addLoading());

  try {
    await StatisticRequests.remove(id);
    callback();
  } catch (err) {
    message.error(I18n.t('routes.panel.statistics.errors.generic'));
  } finally {
    dispatch(removeLoading());
  }
};

export const getUsersForStatisticsPaginated = (params) => async (dispatch) => {
  try {
    const usersForStatistics = await StatisticRequests.getUsersForStatisticsPaginated(params);
    dispatch({
      type: ACTION_SAVE_USER_FOR_STATISTICS,
      payload: usersForStatistics,
    });
  } catch (err) {
    throw new Error(err);
  }
};

export const getQuizStats = (params) => async (dispatch) => {
  try {
    const res = await StatisticRequests.getQuizStats(params);

    dispatch({
      type: ACTION_SAVE_QUIZ_STATS,
      payload: res.quizStats,
    });
  } catch (err) {
    throw new Error(err);
  }
};

export const getStudentProgres = (params) => async (dispatch) => {
  try {
    const res = await StatisticRequests.getStudentProgres(params);

    dispatch({
      type: ACTION_SAVE_STUDENTS_PROGRESS,
      payload: res.studentProgres,
    });
  } catch (err) {
    throw new Error(err);
  }
};