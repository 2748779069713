import React, { useState } from 'react';
import { Alert, Button, Col, Layout, Modal, Row } from 'antd';
import { ContainerOutlined } from '@ant-design/icons';
import { I18n } from 'react-redux-i18n';
import { TrailCompositionCollapsibleItem } from '../../../../../components/TrailCompositionCollapsibleItem/TrailCompositionCollapsibleItem';
import {
  trailsTemplates,
  trailsTemplatesSelectableList,
  TrailTemplate,
} from '../../../../../app/enum/trails';
import { SimpleVerticalDragDrop } from '../../../../../components/SimpleVerticalDragDrop/SimpleVerticalDragDrop';
import { reorderList } from '../../../../../app/utils/order';
import AdvancedSelect from '../../../../../components/shared/AdvancedSelect/AdvancedSelect';

const { Content } = Layout;

export const trailContentsKey = 'contents';
export const trailTemplateTypeKey = 'trailType';

export const TrailContentComposition = ({
  onNext,
  onChange,
  saveButton,
  fields,
}) => {
  const [trailComposition, setTrailComposition] = useState(
    fields[trailContentsKey],
  );
  const [deleteModal, setDeleteModal] = useState(false);

  const onRemoveItem = (itemIndex) => {
    setTimeout(() => {
      setTrailComposition((prev) => {
        const newList = [...prev];
        newList.splice(itemIndex, 1);
        onChange(trailTemplateTypeKey, TrailTemplate.CUSTOM);
        onChange(trailContentsKey, newList);
        return newList;
      });
    }, [100]);
  };

  const onUndoItem = (indexToUndo) => {
    setTrailComposition((prev) => {
      const newList = [...prev].map((item, currentIndex) => ({
        ...item,
        newContent: currentIndex === indexToUndo ? undefined : item.newContent,
      }));
      onChange(trailContentsKey, newList);
      return newList;
    });
  };

  const handleAddNewItem = () => {
    setTrailComposition((prev) => {
      const newList = prev.concat({
        id: null,
        fakeId: Math.random().toString(),
        content: null,
        newContent: null,
      });
      onChange(trailTemplateTypeKey, TrailTemplate.CUSTOM);
      onChange(trailContentsKey, newList);
      return newList;
    });
  };

  const handleReorderList = (dragResponse) => {
    if (dragResponse.source.index === dragResponse.destination.index) return;

    setTrailComposition((prevList) => {
      const newList = reorderList(
        prevList,
        dragResponse.source.index,
        dragResponse.destination.index,
      );

      onChange(trailTemplateTypeKey, TrailTemplate.CUSTOM);
      onChange(trailContentsKey, newList);
      return newList;
    });
  };

  const defineItemContent = (newContents, fakeId) => {  
    setTrailComposition((prev) => {
      let someContentTypeChanged = false;
      const contentsArray = Array.isArray(newContents) ? newContents : [newContents];
  
      const index = prev.findIndex((item) => item.fakeId === fakeId);
      const newList = [...prev];
  
      if (index !== -1) {
        newList.splice(index, 1, ...contentsArray.map((content, i) => {
          const existingItem = prev[index];
  
          if (existingItem && existingItem.content?.type !== content?.type) {
            someContentTypeChanged = true;
          }
  
          return {
            id: null,
            fakeId: i === 0 ? fakeId : crypto.randomUUID(),
            content,
            newContent: null
          };
        }));
      } else {
        newList.push(...contentsArray.map((content) => ({
          id: null,
          fakeId: crypto.randomUUID(),
          content,
          newContent: null
        })));
      }
  
      if (someContentTypeChanged) {
        onChange(trailTemplateTypeKey, TrailTemplate.CUSTOM);
      }
  
      onChange(trailContentsKey, newList);      
      return newList;
    });
  };  

  const handleChangeTrailTemplate = (newTemplateKey) => {
    const newTemplate = trailsTemplates[newTemplateKey];

    if (newTemplate) {
      return setDeleteModal(true)
    }

    onChange(trailTemplateTypeKey, newTemplateKey);
  };

  const changeTrailToBasic = () => {
    const newTemplate = trailsTemplates[TrailTemplate.BASIC];

    setTrailComposition(newTemplate);
    onChange(trailContentsKey, newTemplate);
    onChange(trailTemplateTypeKey, TrailTemplate.BASIC);
    setDeleteModal(false);
  };

  return (
    <Content className='panel__layout__content trail_step_container'>
      <Row align='middle'>
        <h3>
          <ContainerOutlined />
          {I18n.t('routes.panel.knowledgeTrails.create.contents.header')}
        </h3>
      </Row>

      <div className='trail_step_content'>
        <div className='select_template_container'>
          <AdvancedSelect
            options={trailsTemplatesSelectableList}
            label={I18n.t(
              'routes.panel.knowledgeTrails.create.informations.fields.template.label',
            )}
            value={fields[trailTemplateTypeKey]}
            onSelect={handleChangeTrailTemplate}
          />
        </div>

        <Alert
          message={I18n.t('routes.panel.knowledgeTrails.create.contents.warning.title')}
          description={I18n.t('routes.panel.knowledgeTrails.create.contents.warning.description')}
          type="info"
          showIcon
        />

        <Col className='select_contents_container'>
          <SimpleVerticalDragDrop
            items={trailComposition}
            onDragEnd={handleReorderList}
            extractId={(item) => item.id || item.fakeId}
            renderItem={(trailItem, rowIndex) => (
              <TrailCompositionCollapsibleItem
                canUndo={!!trailItem.newContent && !!trailItem.content?.id}
                content={trailItem.newContent || trailItem.content}
                key={trailItem.fakeId || trailItem.id}
                defineItemContent={(newContent) =>
                  defineItemContent(
                    newContent,
                    trailItem.fakeId || trailItem.id,
                  )
                }
                onRemove={() => onRemoveItem(rowIndex)}
                handleUndo={() => onUndoItem(rowIndex)}
              />
            )}
          />
          <Button
            type='primary'
            style={{ marginTop: 20 }}
            onClick={handleAddNewItem}
          >
            {I18n.t(
              'routes.panel.knowledgeTrails.create.contents.addItem',
            )}
          </Button>
        </Col>
      </div>

      <Row justify='end'
        className='buttons_container'>
        <Button disabled={!saveButton.canSave}
          onClick={saveButton.onSave}>
          {saveButton.label}
        </Button>
        <Button type='primary'
          onClick={onNext}>
          {I18n.t('routes.panel.knowledgeTrails.create.button.goNext')}
        </Button>
      </Row>

      <Modal
        visible={deleteModal}
        onOk={changeTrailToBasic}
        onCancel={() => setDeleteModal(false)}
      >
        <p>
          {I18n.t('routes.panel.knowledgeTrails.create.contents.modal.returnBasicTrail')}
        </p>
      </Modal>
    </Content>
  );
};
