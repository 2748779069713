import { Col, Collapse, DatePicker, Layout, Row } from 'antd';
import React, { useState } from 'react';
import { I18n } from 'react-redux-i18n';
import { SearchOutlined } from '@ant-design/icons';
import AdvancedInput from '../shared/AdvancedInput/AdvancedInput';
import AdvancedButton from '../shared/AdvancedButton/AdvancedButton';
import AdvancedSelect from '../shared/AdvancedSelect/AdvancedSelect';

const { Content } = Layout;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;

export const ReportsFilter = ({onSubmit, loading, courses, defaultFilters}) => {
  const [ course, setCourse ] = useState(defaultFilters?.courseId || '')
  const [ email, setEmail ] = useState(defaultFilters?.email || '')
  const [ date, setDate ] = useState(defaultFilters?.date || '')

  const handleSubmit = (e) => {
    e?.preventDefault && e.preventDefault()

    onSubmit({
      courseId: course,
      email,
      date
    })
  };

  const clearFilters = () => {
    setCourse('')
    setEmail('')
    setDate('')
    onSubmit({})
  };

  return (
    <Content className='panel__layout__content panel__layout__content--advanced-filter'>
      <Collapse defaultActiveKey='1'
        className='advanced-filter'>
        <Panel header={I18n.t('shared.advancedFilters.title')}
          key='1'>
          <form
            onSubmit={handleSubmit}
          >
            <Row gutter={16}>
              <Col span={8}>
                <AdvancedSelect
                  options={courses}
                  label={I18n.t('forms.reportsFilter.course')}
                  value={course}
                  onChange={setCourse}
                />
              </Col>
              <Col span={8}>
                <AdvancedInput
                  value={email}
                  onChange={setEmail}
                  placeholder={I18n.t('shared.type')}
                  label={I18n.t('forms.reportsFilter.email')}
                />
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={8}>
                <div style={{ paddingBottom: '5px' }}>
                  {I18n.t('forms.reportsFilter.date')}
                </div>
                <RangePicker
                  placeholder={[
                    I18n.t('shared.advancedFilters.form.startDate'),
                    I18n.t('shared.advancedFilters.form.endDate'),
                  ]}
                  format='DD/MM/YYYY'
                  value={date}
                  onChange={setDate}
                  style={{ width: '100%' }}
                />
              </Col>
            </Row>

            <Row gutter={16}>
              <Col
                span={24}
                className='advanced-filter__search-button text-right'
              >
                <AdvancedButton
                  type='link'
                  text={I18n.t('shared.advancedFilters.clearButtonText')}
                  onClick={clearFilters}
                />
                <AdvancedButton
                  loading={loading}
                  htmlType='submit'
                  text={I18n.t('shared.advancedFilters.filterButtonText')}
                  icon={<SearchOutlined />}
                />
              </Col>
            </Row>
          </form>
        </Panel>
      </Collapse>
    </Content>
  )
};