import React from 'react';
import { connect } from 'react-redux';
import {
  Checkbox,
  Col,
  Divider,
  message,
  Row,
} from 'antd';
import * as yup from 'yup';
import { I18n } from 'react-redux-i18n';
import { SaveOutlined } from '@ant-design/icons';
import AdvancedInput from '../../../components/shared/AdvancedInput';
import { UserActions } from '../../redux/actions';
import { AuthSelectors, LoadingSelectors, UserSelectors } from '../../redux/reducers';
import AdvancedButton from '../../../components/shared/AdvancedButton';
import { ProfileType } from '../../enum/profileType';
import { ViewType } from '../../enum/viewType';
import { validateEmail } from '../../utils/string';
import AdvancedRadioGroup from '../../../components/shared/AdvancedRadioGroup/AdvancedRadioGroup';

class AdministratorForm extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      changePasswordVisible: false,
      form: {
        name: null,
        email: null,
        password: null,
        passwordConfirmation: null,
      },
    };
  }

  async componentDidMount () {
    const { me, userDetails } = this.props;

    if (userDetails) {
      const saveState = {
        form: {
          ...userDetails,
        },
      };

      saveState.form.profileTypeSelected = userDetails.profileType;

      this.setState(saveState, () => {
        const { form } = this.state;

        if (me && me.profileType !== ProfileType.ADMIN) {
          this.setState({
            form: {
              ...form,
              profileTypeSelected: userDetails.profileType
            },
          });
        }
      });
    }
  }

  // eslint-disable-next-line no-unused-vars
  UNSAFE_componentWillReceiveProps (nextProps, nextContext) {
    const {
      userDetails,
      id,
      me,
    } = this.props;

    if (userDetails && id !== 'add') {
      this.setState({
        changePasswordVisible: false,
        form: {
          name: userDetails && userDetails.name,
          email: userDetails && userDetails.email,
          password: null,
          passwordConfirmation: null,
        },
      }, () => {
        const { form } = this.state;

        if (me && me.profileType !== ProfileType.ADMIN) {
          this.setState({
            form: {
              ...form,
            },
          });
        }
      });
    }
  }

  onSubmit (e) {
    e.preventDefault();
    let { form } = this.state;
    const { viewType, me } = this.props;

    if (!validateEmail(form.email)) {
      return message.error(I18n.t('routes.panel.administratorDetails.messages.errors.email'));
    }

    const userSchema = yup.object().shape({
      name: yup.string().required(),
    });

    userSchema
      .isValid(form)
      .then((valid) => {
        if (!valid) {
          message.error(I18n.t('routes.panel.administratorDetails.messages.errors.generic'));
        } else {
          if (viewType === ViewType.ADD || this.state.changePasswordVisible) {
            if (!(form.password && form.passwordConfirmation)) {
              return message.error(I18n.t('routes.panel.administratorDetails.messages.errors.passwordsRequired'));
            }

            if (form.password !== form.passwordConfirmation) {
              return message.error(I18n.t('routes.panel.administratorDetails.messages.errors.passwordsMismatch'));
            }
          }

          const { data, submitFunction } = this.props;

          if (me && me.profileType === ProfileType.ADMIN) {
            form = {
              ...form,
            };
          }

          if (viewType === ViewType.EDIT && !this.state.changePasswordVisible) {
            delete form.password;
            delete form.passwordConfirmation;
          }

          submitFunction(data ? data.id : null, form);
        }
      });
  }

  fieldChange (name, value) {
    const { form } = this.state;
    form[name] = value;
    this.setState({ form });
  }

  render () {
    const {
      loading, viewType,
    } = this.props;
    const { form, changePasswordVisible } = this.state;

    return (
      <Row>
        {loading === 0 && (
          <Col>
            <form
              name="administratorForm"
              onSubmit={(ev) => this.onSubmit(ev)}
            >
              <Row gutter={24}>
                <Col span={10}>
                  <AdvancedInput
                    label={I18n.t('forms.administrator.email.label')}
                    value={form && form.email}
                    onChange={(val) => this.fieldChange('email', val)}
                    disabled={viewType === ViewType.EDIT}
                  />
                </Col>
                <Col span={10}>
                  <AdvancedInput
                    label={I18n.t('forms.administrator.name.label')}
                    value={form && form.name}
                    onChange={(val) => this.fieldChange('name', val)}
                  />
                </Col>
              </Row>

              {viewType === ViewType.EDIT && (
                <Row
                  gutter={24}
                  className="form-password-divider"
                >
                  <Col span={24}>
                    <Checkbox
                      onChange={(e) => this.setState({ changePasswordVisible: e.target.checked })}
                    >
                      {I18n.t('routes.panel.administratorDetails.changePasswordPhrase')}
                    </Checkbox>
                  </Col>
                </Row>
              )}

              {(viewType === ViewType.ADD || changePasswordVisible) && (
                <Row gutter={24}>
                  <Col span={10}>
                    <AdvancedInput
                      label={I18n.t('forms.administrator.password.label')}
                      value={form && form.password}
                      onChange={(val) => this.fieldChange('password', val)}
                      isPassword
                    />
                  </Col>
                  <Col span={10}>
                    <AdvancedInput
                      label={I18n.t('forms.administrator.passwordConfirmation.label')}
                      value={form && form.passwordConfirmation}
                      onChange={(val) => this.fieldChange('passwordConfirmation', val)}
                      isPassword
                    />
                  </Col>
                </Row>
              )}

              <Col span={25}>
                <AdvancedRadioGroup
                  value={form && form.profileTypeSelected}
                  label={I18n.t('forms.administrator.profileType.title')}
                  onChange={(val) => this.fieldChange('profileTypeSelected', val.target.value)}
                  options={[
                    {
                      id: ProfileType.ADMIN,
                      name: I18n.t('forms.administrator.profileType.profileTypeAdmin'),
                      config: I18n.t('forms.administrator.profileTypeConfig.configTypeAdmin')
                    },
                    {
                      id: ProfileType.OPERATOR,
                      name: I18n.t('forms.administrator.profileType.profileTypeOperator'),
                      config: I18n.t('forms.administrator.profileTypeConfig.configTypeOperator')
                    },
                    {
                      id: ProfileType.MARKETING,
                      name: I18n.t('forms.administrator.profileType.profileTypeMarketing'),
                      config: I18n.t('forms.administrator.profileTypeConfig.configTypeMarketing')
                    },
                    {
                      id: ProfileType.QUIZER,
                      name: I18n.t('forms.administrator.profileType.profileTypeQuizer'),
                      config: I18n.t('forms.administrator.profileTypeConfig.configTypeQuizer')
                    },
                    {
                      id: ProfileType.TEACHER,
                      name: I18n.t('forms.administrator.profileType.profileTypeTeacher'),
                      config: I18n.t('forms.administrator.profileTypeConfig.configTypeTeacher')
                    },
                    {
                      id: ProfileType.UPLOADER,
                      name: I18n.t('forms.administrator.profileType.profileTypeUploader'),
                      config: I18n.t('forms.administrator.profileTypeConfig.configTypeUploader')
                    },
                    {
                      id: ProfileType.KANBAN_MANAGER,
                      name: I18n.t('forms.administrator.profileType.profileTypeKanbanManager'),
                      config: I18n.t('forms.administrator.profileTypeConfig.configTypeKanban')
                    },
                    {
                      id: ProfileType.TRAIL_MANAGER,
                      name: I18n.t('forms.administrator.profileType.profileTypeTrailManager'),
                      config: I18n.t('forms.administrator.profileTypeConfig.configTypeTrail')
                    },
                  ]}
                />
              </Col>

              <Divider />

              <Row>
                <Col
                  span={24}
                  className="text-right"
                >
                  <AdvancedButton
                    type="link"
                    text={I18n.t('forms.goBackButtonText')}
                    href={I18n.t('routes.panel.administrators.url')}
                  />
                  <Divider
                    className="form-button-divider"
                    type="vertical"
                  />
                  <AdvancedButton
                    htmlType="submit"
                    text={I18n.t('shared.save')}
                    icon={<SaveOutlined />}
                  />
                </Col>
              </Row>
            </form>
          </Col>
        )}
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
  userDetails: UserSelectors.getUserDetails(state),
});

const mapDispatchToProps = (dispatch) => ({
  uploadImportCsv: (csvData, callback) => dispatch(
    UserActions.uploadImportCsv(csvData, callback),
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  AdministratorForm,
);
