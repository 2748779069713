import React from 'react';
import Icon, {
  DownOutlined, FileTextOutlined, MenuFoldOutlined, MenuUnfoldOutlined,
} from '@ant-design/icons';
import {
  Avatar,
  Button,
  Col,
  Dropdown,
  Layout,
  Menu,
  notification,
  Row,
} from 'antd';
import { connect } from 'react-redux';
import {
  I18n,
} from 'react-redux-i18n';
import { Helmet } from 'react-helmet';
import { Link } from '@reach/router';

import AuthService from '../services/auth';
import * as AuthSelectors from '../redux/reducers/auth';
import { AuthActions, ChatActions } from '../redux/actions';

import { ReactComponent as UserIcon } from '../assets/img/user_panel_icon.svg';
import { ReactComponent as CourseIcon } from '../assets/img/course_panel_icon.svg';
import { ReactComponent as NotificationIcon } from '../assets/img/notification_panel_icon.svg';
import { ReactComponent as Chat } from '../assets/img/chat_panel_icon.svg';
import { ReactComponent as ConfigurationIcon } from '../assets/img/configuration_panel_icon.svg';
import { ReactComponent as QuizIcon } from '../assets/img/ic_quiz.svg';
import { ReactComponent as DashboardIcon } from '../assets/img/dashboard_panel_icon.svg';
import { ReactComponent as NotesIcon } from '../assets/img/notes_icon.svg';
import { ReactComponent as QuestionDatabaseIcon } from '../assets/img/database_questions_icon.svg';
import AdvancedButton from '../../components/shared/AdvancedButton/AdvancedButton';
import { getInitials } from '../utils/string';
import { ProfileType } from '../enum/profileType';
import { ChatSelectors } from '../redux/reducers';

class Panel extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      collapsed: false,
      dropdownVisible: false,
      haveMessage: false,
      params: {
        page: 1,
        pageSize: 10,
        type: 3,
        read: 2,
      }
    };
  }

  async componentDidMount () {
    const{ getChatPaginated, me } = this.props;
    const{ params } = this.state;

    if (!this.props.me) {
      await this.props.getMe();

      if (!this.props.me || ![
        ProfileType.ADMIN,
        ProfileType.OPERATOR,
        ProfileType.KANBAN_MANAGER,
        ProfileType.TRAIL_MANAGER,
      ].includes(this.props.me.profileType)) {
        AuthService.reset();
        AuthService.redirectToLogin();
      }
    }

    if(([
      ProfileType.ADMIN,
      ProfileType.TEACHER,
      ProfileType.OPERATOR,
    ].includes(me?.profileType))) {
      await getChatPaginated(params)
      this.checkMessageRead()
    }
  }

  checkMessageRead = () => {
    const {chatPaginated} = this.props;
    const whoSentMessage = chatPaginated && chatPaginated.rows ? chatPaginated.rows.map(whoSentMessage => whoSentMessage.messages[whoSentMessage.messages.length - 1]) : [];
    const getProfileWhoSentMessage = whoSentMessage.filter(profileType => profileType.user.profileType === ProfileType.USER)
    const getProfileTypeSentMessage = getProfileWhoSentMessage && getProfileWhoSentMessage.length && getProfileWhoSentMessage[0].user.profileType

    if(chatPaginated && chatPaginated.rows.length && getProfileTypeSentMessage === ProfileType.USER) {
      this.setState({haveMessage: true})
    } else {
      this.setState({haveMessage: false})
    }

  }

  getCurrent = () => window.location.pathname

  toggle = () => {
    const { collapsed } = this.state;
    this.setState(
      {
        collapsed: !collapsed,
      },
    );
  };

  openNotification () {
    try {
      const { logout } = this.props;
      const key = `open${Date.now()}`;
      const btn = (
        <Button
          type="primary"
          size="small"
          onClick={() => {
            logout();
            notification.close(key);
          }}
        >
          {I18n.t('shared.logout.confirmButton')}
        </Button>
      );
      notification.open({
        message: I18n.t('shared.logout.confirmation'),
        btn,
        key,
      });
    } catch (err) {
      //
    }
  }

  render () {
    const {
      title,
      container,
      id,
      me,
      location,
    } = this.props;
    const { dropdownVisible, haveMessage } = this.state;
    const { Header, Sider } = Layout;
    const { SubMenu } = Menu;
    return (
      me && me.profileType !== ProfileType.USER ? (
        <Layout className="panel__layout">
          <Helmet>
            <title>{title}</title>
          </Helmet>
          <Sider
            trigger={null}
            collapsible
            collapsed={this.state.collapsed}
          >
            <div className="logo" />

            <Menu
              theme="dark"
              mode="inline"
              className="panel__layout__menu"
              selectedKeys={[ this.getCurrent() ]}
            >

              <div className="panel__layout__userinfo">
                <div className="panel__layout__userinfo__name">
                  <h4> {I18n.t('routes.panel.administrative')}</h4>
                </div>
              </div>

              <div className="panel__layout__menu__img__wrapper">
                <img
                  alt="sidebar logo"
                  className="panel__layout__menu__img"
                  src="/assets/img/logo.svg"
                />
              </div>
              {(me?.profileType === ProfileType.ADMIN || me?.profileType === ProfileType.MARKETING ||
                me?.profileType === ProfileType.OPERATOR) && (
                <Menu.Item
                  key={`${I18n.t('routes.panel.statistics.url')}`}
                  icon={(
                    <Icon
                      component={DashboardIcon}
                      className="menu-icon"
                    />
                  )}
                >
                  <Link to={`${I18n.t('routes.panel.statistics.url')}`}>
                    <span>
                      {I18n.t('routes.panel.statistics.sidebarTitle')}
                    </span>
                  </Link>
                </Menu.Item>
              )}

              {me?.profileType === ProfileType.ADMIN && (
                <SubMenu
                  key={I18n.t('routes.panel.reports.url')}
                  className="panel-menu-item"
                  icon={<FileTextOutlined />}
                  title={I18n.t('routes.panel.reports.sidebarTitle')}
                >
                  <Menu.Item
                    key={`${I18n.t('routes.panel.quizReports.url')}`}
                  >
                    <Link to={`${I18n.t('routes.panel.quizReports.url')}`}>
                      <span>
                        {I18n.t('routes.panel.quizReports.sidebarTitle')}
                      </span>
                    </Link>
                  </Menu.Item>

                  <Menu.Item
                    key={`${I18n.t('routes.panel.studentsProgressReports.url')}`}
                  >
                    <Link to={`${I18n.t('routes.panel.studentsProgressReports.url')}`}>
                      <span>
                        {I18n.t('routes.panel.studentsProgressReports.sidebarTitle')}
                      </span>
                    </Link>
                  </Menu.Item>
                </SubMenu>
              )}

              {(me?.profileType === ProfileType.ADMIN || me?.profileType === ProfileType.MARKETING ||
                me?.profileType === ProfileType.OPERATOR || me?.profileType === ProfileType.KANBAN_MANAGER) && (
                <SubMenu
                  key={I18n.t('routes.panel.users.url')}
                  className="panel-menu-item"
                  icon={(
                    <Icon
                      component={UserIcon}
                      className="menu-icon"
                    />
                  )}
                  title={I18n.t('routes.panel.users.sidebarTitle')}
                >
                  {(me?.profileType !== ProfileType.KANBAN_MANAGER && me?.profileType !== ProfileType.TRAIL_MANAGER) && (
                    <Menu.Item key={I18n.t('routes.panel.users.url')}>
                      <Link to={`${I18n.t('routes.panel.users.url')}`}>
                        <span>
                          {I18n.t('routes.panel.users.sidebarItemList')}
                        </span>
                      </Link>
                    </Menu.Item>
                  )}
                  {(me?.profileType !== ProfileType.OPERATOR && me?.profileType !== ProfileType.KANBAN_MANAGER && me?.profileType !== ProfileType.KANBAN_MANAGER) && (
                    <Menu.Item key={I18n.t('routes.panel.administrators.url')}>
                      <Link to={`${I18n.t('routes.panel.administrators.url')}`}>
                        <span>
                          {I18n.t('routes.panel.administrators.sidebarTitle')}
                        </span>
                      </Link>
                    </Menu.Item>
                  )}
                  {(me?.profileType === ProfileType.ADMIN || me?.profileType === ProfileType.KANBAN_MANAGER) && (
                    <Menu.Item key={I18n.t('routes.panel.userRequests.url')}>
                      <Link to={`${I18n.t('routes.panel.userRequests.url')}`}>
                        <span>
                          {I18n.t('routes.panel.users.kanbanList')}
                        </span>
                      </Link>
                    </Menu.Item>
                  )}
                </SubMenu>
              )}
              {(me?.profileType === ProfileType.ADMIN || me?.profileType === ProfileType.UPLOADER ||
                me?.profileType === ProfileType.OPERATOR || me?.profileType === ProfileType.TRAIL_MANAGER) && (
                <SubMenu
                  key={I18n.t('routes.panel.courses.url')}
                  className="panel-menu-item"
                  icon={(
                    <Icon
                      component={CourseIcon}
                      className="menu-icon"
                    />
                  )}
                  title={I18n.t('routes.panel.knowledge.sidebarTitle')}
                >
                  {(me?.profileType !== ProfileType.TRAIL_MANAGER) && (
                  <Menu.Item key={`${I18n.t('routes.panel.courses.url')}`}>
                    <Link to={`${I18n.t('routes.panel.courses.url')}`}>
                      <span>
                        {I18n.t('routes.panel.courses.sidebarTitle')}
                      </span>
                    </Link>
                  </Menu.Item>
                  )}
                  <Menu.Item key={`${I18n.t('routes.panel.knowledgeTrails.url')}`}>
                    <Link to={`${I18n.t('routes.panel.knowledgeTrails.url')}`}>
                      <span>
                        {I18n.t('routes.panel.knowledgeTrails.sidebarTitle')}
                      </span>
                    </Link>
                  </Menu.Item>
                  {(me?.profileType !== ProfileType.TRAIL_MANAGER) && (
                  <Menu.Item key={`${I18n.t('routes.panel.modules.url')}`}>
                    <Link to={`${I18n.t('routes.panel.modules.url')}`}>
                      <span>
                        {I18n.t('routes.panel.modules.sidebarTitle')}
                      </span>
                    </Link>
                  </Menu.Item>
                  )}
                  {(me?.profileType !== ProfileType.TRAIL_MANAGER) && (
                  <Menu.Item
                    key={`${I18n.t('routes.panel.contents.url')}`}
                  >
                    <Link to={`${I18n.t('routes.panel.contents.url')}`}>
                      <span>
                        {I18n.t('routes.panel.contents.sidebarTitle')}
                      </span>
                    </Link>
                  </Menu.Item>
                  )}
                </SubMenu>
              )}
              {([
                ProfileType.ADMIN,
                ProfileType.QUIZER,
                ProfileType.TEACHER,
                ProfileType.OPERATOR,
              ].includes(me?.profileType)) && (
                <SubMenu
                  key={I18n.t('routes.panel.quiz.url')}
                  className="panel-menu-item"
                  icon={(
                    <Icon
                      component={QuizIcon}
                      className="menu-icon"
                    />
                  )}
                  title={I18n.t('routes.panel.quiz.titleQuiz')}
                >
                  <Menu.Item key={I18n.t('routes.panel.quiz.urlQuestion')}>
                    <Link to={I18n.t('routes.panel.quiz.urlQuestion')}>
                      <span>
                        {I18n.t('routes.panel.quiz.sidebarItemList')}
                      </span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={I18n.t('routes.panel.quiz.urlQuiz')}>
                    <Link to={I18n.t('routes.panel.quiz.urlQuiz')}>
                      <span>
                        {I18n.t('routes.panel.quiz.pageQuiz')}
                      </span>
                    </Link>
                  </Menu.Item>
                </SubMenu>
              )}
              {([
                ProfileType.ADMIN,
                ProfileType.OPERATOR,
                ProfileType.TEACHER,
              ].includes(me?.profileType)) && (
                <>
                  <SubMenu
                    key={I18n.t('routes.panel.questionDatabase.key')}
                    className="panel-menu-item"
                    icon={(
                      haveMessage ?
                        (
                          <div className="container-dots">
                            <div className="dots-message-submenu" />
                            <Icon
                              component={QuestionDatabaseIcon}
                              className="menu-icon"
                            />
                          </div>
                        ) : (
                          <Icon
                            component={QuestionDatabaseIcon}
                            className="menu-icon"
                          />
                        )

                    )}
                    title={I18n.t('routes.panel.questionDatabase.sidebarTitle')}
                  >
                    {([
                      ProfileType.ADMIN,
                      ProfileType.OPERATOR,
                      ProfileType.TRAIL_MANAGER,
                    ].includes(me?.profileType)) && (
                      <Menu.Item
                        key={I18n.t('routes.panel.questionDatabase.url')}
                      >
                        <Link to={I18n.t('routes.panel.questionDatabase.url')}>
                          <span>
                            {I18n.t('routes.panel.questionDatabase.subMenu.item.userQuestionDatabaseList')}
                          </span>
                        </Link>
                      </Menu.Item>
                    )}
                    {haveMessage ?
                      (
                        <div className="container-dots__menu-item">
                          <div className="dots-message-menu-item" />
                          <Menu.Item
                            key={I18n.t('routes.panel.questionDatabase.chat.url')}
                          >
                            <Link to={I18n.t('routes.panel.questionDatabase.chat.url')}>
                              <span>
                                {I18n.t('routes.panel.questionDatabase.subMenu.item.chatQuestionDatabase')}
                              </span>
                            </Link>
                          </Menu.Item>
                        </div>
                      )
                      :
                      (
                        <Menu.Item
                          key={I18n.t('routes.panel.questionDatabase.chat.url')}
                        >
                          <Link to={I18n.t('routes.panel.questionDatabase.chat.url')}>
                            <span>
                              {I18n.t('routes.panel.questionDatabase.subMenu.item.chatQuestionDatabase')}
                            </span>
                          </Link>
                        </Menu.Item>
                      )}
                    <Menu.Item
                      key={I18n.t('routes.panel.questionDatabase.corrections.url')}
                    >
                      <Link to={I18n.t('routes.panel.questionDatabase.corrections.url')}>
                        <span>
                          {I18n.t('routes.panel.questionDatabase.corrections.sideBarTitle')}
                        </span>
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                </>
              )}
              {([
                ProfileType.ADMIN,
              ].includes(me?.profileType)) && (
                <Menu.Item
                  key={I18n.t('routes.panel.releaseNotes.url')}
                  icon={(
                    <Icon
                      component={NotesIcon}
                      className="menu-icon"
                    />
                  )}
                >
                  <Link to={I18n.t('routes.panel.releaseNotes.url')}>
                    <span>
                      {I18n.t('routes.panel.releaseNotes.sidebarTitle')}
                    </span>
                  </Link>
                </Menu.Item>
              )}
              {(me?.profileType === ProfileType.ADMIN || me?.profileType === ProfileType.MARKETING ||
                me?.profileType === ProfileType.OPERATOR) && (
                <Menu.Item
                  key={I18n.t('routes.panel.pushNotifications.url')}
                  icon={(
                    <Icon
                      component={NotificationIcon}
                      className="menu-icon"
                    />
                  )}
                >
                  <Link to={I18n.t('routes.panel.pushNotifications.url')}>
                    <span>
                      {I18n.t('pushNotification.sidebarTitle')}
                    </span>
                  </Link>
                </Menu.Item>
              )}
              {([ ProfileType.ADMIN, ProfileType.TEACHER, ProfileType.OPERATOR ].includes(me?.profileType)) && (
                <Menu.Item
                  key={I18n.t('routes.panel.chat.url')}
                  icon={(
                    <Icon
                      component={Chat}
                      className="menu-icon"
                    />
                  )}
                >
                  <Link to={I18n.t('routes.panel.chat.url')}>
                    <span>
                      {I18n.t('chat.sidebarTitle')}
                    </span>
                  </Link>
                </Menu.Item>
              )}
              {([ ProfileType.ADMIN, ProfileType.OPERATOR ].includes(me?.profileType)) && (
                <SubMenu
                  key={I18n.t('routes.panel.configuration.url')}
                  className="panel-menu-item"
                  icon={(
                    <Icon
                      component={ConfigurationIcon}
                      className="menu-icon"
                    />
                  )}
                  title={I18n.t('routes.panel.configuration.sidebarTitle')}
                >
                  <Menu.Item key={`${I18n.t('routes.panel.categories.url')}`}>
                    <Link to={`${I18n.t('routes.panel.categories.url')}`}>
                      <span>
                        {I18n.t('routes.panel.categories.sidebarTitle')}
                      </span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={`${I18n.t('routes.panel.hospitals.url')}`}>
                    <Link to={`${I18n.t('routes.panel.hospitals.url')}`}>
                      <span>
                        {I18n.t('routes.panel.hospitals.sidebarTitle')}
                      </span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={`${I18n.t('routes.panel.companies.url')}`}>
                    <Link to={`${I18n.t('routes.panel.companies.url')}`}>
                      <span>
                        {I18n.t('routes.panel.companies.sidebarTitle')}
                      </span>
                    </Link>
                  </Menu.Item>
                </SubMenu>
              )}

            </Menu>

          </Sider>
          <Layout className="panel__layout__wrapper">
            <Header className="panel__layout__header">
              <Row>
                <Col span={18}>
                  <Icon
                    component={this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined}
                    className="panel__trigger_button"
                    onClick={this.toggle}
                  />
                </Col>
                <Col
                  span={6}
                >
                  <div className="panel__layout__header__avatar">
                    <Dropdown
                      onVisibleChange={(ev) => this.setState({ dropdownVisible: ev })}
                      overlay={(
                        <Menu>
                          <Menu.Item>
                            <div className="panel__layout__header__avatar__userdata">
                              <strong>
                                {me && me.name}<br />
                              </strong>
                              <small>{me && me.email}</small>
                              {me && me.company && (
                                <span className="panel__layout__header__avatar__userdata__company">
                                  {me.company.name}
                                </span>
                              )}
                            </div>
                          </Menu.Item>
                          <Menu.Item>
                            <AdvancedButton
                              onClick={() => this.openNotification()}
                              text={I18n.t('shared.logout.menuText')}
                              type="link"
                            />
                          </Menu.Item>
                        </Menu>
                      )}
                    >
                      <span>
                        <Avatar
                          className="panel__layout__header__avatar__item"
                          size={32}
                        >
                          {me && getInitials(me.name)}
                        </Avatar>
                        <DownOutlined
                          className="panel__layout__header__avatar__down"
                          rotate={dropdownVisible ? 180 : 0}
                        />
                      </span>
                    </Dropdown>
                  </div>
                </Col>
              </Row>
            </Header>

            {me && (id ? React.cloneElement(container, { id, location }) : container)}

            <div className="panel__layout__content__footer">
              {I18n.t('application.footer.poweredBy')}&nbsp;
              <a
                type="link"
                href={I18n.t('application.footer.poweredByBrandUrl')}
                target="_blank"
                rel="noopener noreferrer"
              >
                {I18n.t('application.footer.poweredByBrand')}
              </a> - {I18n.t('application.version.title')}: {I18n.t('application.version.number')}
            </div>
          </Layout>
        </Layout>
      ) : null

    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: AuthSelectors.isAuthenticated(state),
  me: AuthSelectors.getMe(state),
  chatPaginated: ChatSelectors.getChatPaginated(state),
});

const mapDispatchToProps = (dispatch) => ({
  logout: (user) => dispatch(AuthActions.logout(user)),
  getMe: () => dispatch(AuthActions.getMe()),
  getChatPaginated: (parameters) =>
    dispatch(ChatActions.getChatPaginated(parameters)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  Panel,
);
