export const withLoading = async (fn, setLoading) => {
  try {
    setLoading(true)
    const res = await fn()
    return res
  } catch (err) {
    //
  } finally {
    setLoading(false)
  }
};