import { Button, Col } from 'antd';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import DataTable from '../../shared/DataTable/DataTable';
import { FileSearchOutlined } from '@ant-design/icons';

export const ContentsListToAssign = ({
  data, onDetails, getData, appliedFilters, loading, onSelectRow, selectedRowKeys
}) => {
  return (
    <Col className='contents_list_to_assign_container'>
      <DataTable
        selectedRowKeys={selectedRowKeys}
        notGetOnStart
        getMethod={getData}
        data={data}
        loading={loading}
        showSizeChanger
        pageSizeOptions={['10']}
        params={appliedFilters}
        selectable
        onSelectRow={onSelectRow}
        hideSelectAll
        columns={[
          {
            ellipsis: true,
            title: I18n.t('routes.panel.knowledgeTrails.create.contents.dataTable.title.title'),
            render: (value) => value.title || value.name || value.description || '--',
          },
          {
            width: '50px',
            key: I18n.t('routes.panel.knowledgeTrails.create.contents.dataTable.actions.key'),
            render: (id) => (
              <Button
                type='link'
                icon={<FileSearchOutlined />}
                onClick={() => onDetails(id)}
              />
            ),
          },
        ]}
      />
    </Col>
  )
}