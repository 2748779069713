import React, { useEffect, useState } from 'react';
import {
  Breadcrumb,
  Layout,
  Row,
  Space,
} from 'antd';
import { connect } from 'react-redux';
import {
  CourseActions,
  FilterActions,
  StatisticActions,
} from '../../../app/redux/actions';
import {
  CourseSelectors,
  FilterSelector,
  StatisticSelectors,
} from '../../../app/redux/reducers';
import { I18n } from 'react-redux-i18n';
import { ReportsFilter } from '../../../components/filters/ReportsFilter';
import DataTable from '../../../components/shared/DataTable/DataTable';
import { withLoading } from '../../../app/utils/withLoading';
import { CSVLink } from 'react-csv';
import AdvancedButton from '../../../components/shared/AdvancedButton/AdvancedButton';
import { AppstoreOutlined } from '@ant-design/icons';


const { Content } = Layout;

const QuizReports = (props) => {
  const {
    studentProgress,
    getStudentProgress,
    setStudentProgressFilters,
    studentProgressFilter
  } = props;

  const [ loading, setLoading ] = useState(false);

  const onSubmitFilters = async (values) => {
    if (loading) return;

    const payload = {
      startDate: values?.date?.[0]?.format('YYYY-MM-DD') || undefined,
      endDate: values?.date?.[1]?.format('YYYY-MM-DD') || undefined,
      courseId: values.courseId || undefined,
      userEmail: values.email || undefined
    };

    await withLoading(() => getStudentProgress(payload), setLoading);

    setStudentProgressFilters(values);
  }

  useEffect(() => {
    props.getCourseSelectives()
  }, []);

  return (
    <>
      <Content className='panel__layout__content panel__layout__content--breadcrumb'>
        <Breadcrumb>
          <Breadcrumb.Item>
            <span>{I18n.t('routes.panel.pageTitle')}</span>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <span>{I18n.t('routes.panel.studentsProgressReports.pageTitle')}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Content>

      <ReportsFilter
        defaultFilters={studentProgressFilter}
        loading={loading}
        onSubmit={onSubmitFilters}
        courses={props.courseSelectives} />

      <Space direction="vertical"
        className='panel__layout__content'>
        <Row justify="end">
          <CSVLink
            data={studentProgress || []}
            filename={I18n.t('routes.panel.studentsProgressReports.csvFileName')}
          >
            <AdvancedButton
              icon={<AppstoreOutlined />}
              text={I18n.t('shared.exportAllCsvButtonText')}
              disabled={loading}
              loading={loading}
            />
          </CSVLink>
        </Row>
        <DataTable
          loading={loading}
          notGetOnStart
          data={{
            rows: studentProgress || [],
            count: studentProgress?.length || 0
          }}
          disablePagination
          columns={
            [
              {
                key: I18n.t('routes.panel.studentsProgressReports.dataTable.courseName.key'),
                title: I18n.t('routes.panel.studentsProgressReports.dataTable.courseName.title'),
                render: (value) => value,
              },
              {
                key: I18n.t('routes.panel.studentsProgressReports.dataTable.studentName.key'),
                title: I18n.t('routes.panel.studentsProgressReports.dataTable.studentName.title'),
                render: (value) => value,
              },
              {
                key: I18n.t('routes.panel.studentsProgressReports.dataTable.studentEmail.key'),
                title: I18n.t('routes.panel.studentsProgressReports.dataTable.studentEmail.title'),
                render: (value) => value,
              },
              {
                key: I18n.t('routes.panel.studentsProgressReports.dataTable.modulesAmount.key'),
                title: I18n.t('routes.panel.studentsProgressReports.dataTable.modulesAmount.title'),
                render: (value) => value,
              },
              {
                key: I18n.t('routes.panel.studentsProgressReports.dataTable.contentsAmount.key'),
                title: I18n.t('routes.panel.studentsProgressReports.dataTable.contentsAmount.title'),
                render: (value) => value,
              },
              {
                key: I18n.t('routes.panel.studentsProgressReports.dataTable.userContentsAmount.key'),
                title: I18n.t('routes.panel.studentsProgressReports.dataTable.userContentsAmount.title'),
                render: (value) => value,
              },
              {
                key: I18n.t('routes.panel.studentsProgressReports.dataTable.videoHoursAmount.key'),
                title: I18n.t('routes.panel.studentsProgressReports.dataTable.videoHoursAmount.title'),
                render: (value) => value,
              },
              {
                key: I18n.t('routes.panel.studentsProgressReports.dataTable.videoProgressAmount.key'),
                title: I18n.t('routes.panel.studentsProgressReports.dataTable.videoProgressAmount.title'),
                render: (value) => value,
              },
              {
                key: I18n.t('routes.panel.studentsProgressReports.dataTable.audioHoursAmount.key'),
                title: I18n.t('routes.panel.studentsProgressReports.dataTable.audioHoursAmount.title'),
                render: (value) => value,
              },
              {
                key: I18n.t('routes.panel.studentsProgressReports.dataTable.audioProgressAmount.key'),
                title: I18n.t('routes.panel.studentsProgressReports.dataTable.audioProgressAmount.title'),
                render: (value) => value,
              },
            ]
          }
        />
      </Space>
    </>
  );
}

const mapStateToProps = (state) => ({
  courseSelectives: CourseSelectors.getCourseSelectives(state),

  studentProgress: StatisticSelectors.getStudentProgress(state),
  studentProgressFilter: FilterSelector.getStudentProgressFilter(state)
});

const mapDispatchToProps = (dispatch) => ({
  getCourseSelectives: (params) =>
    dispatch(CourseActions.getCourseSelectivesQuiz(params)),

  getStudentProgress: (props) => dispatch(StatisticActions.getStudentProgres(props)),
  setStudentProgressFilters: (props) => dispatch(FilterActions.setStudentProgresFilters(props))
});

export default connect(mapStateToProps, mapDispatchToProps)(QuizReports);
