import React from 'react';
import { Table } from 'antd';
import { I18n } from 'react-redux-i18n';
import { cleanPagination } from '../../../app/utils/pagination'

export const defaultValues = {
  pageSize: 10,
  total: 0,
  offset: 0,
  page: 1,
  data: null,
};

class DataTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 0,
      data: null,
      page: props.params?.page || 1,
      pageSize: props.params?.pageSize || 10,
    };

    if (!this.props.notGetOnStart) {
      this.props.getMethod(this.state);
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { noPersistentPaginated } = this.props

    if (nextProps.data) {
      let { page, total, pageSize } = nextProps.params || {};

      if (total !== nextProps.data.count && noPersistentPaginated) {
        page = 1;
        pageSize = 10;
      }

      total = nextProps.data.count;
      this.setState({
        total,
        page,
        pageSize,
      });
    }

    this.setState({ data: nextProps.data });
  }

  getColumns = (params) => {
    if (params[0].title !== 'Número' && !this.props.dontShowIndex) {
      params.unshift({
        width: '40px', title: '', dataIndex: 0, key: 'num',
      });
    }

    return (params.map((o) => (
      {
        ...o,
        title: o.title,
        dataIndex: o.key,
        key: o.key,
        render: o.render ? o.render : undefined,
      }
    )));
  }

  getRows = (data) => {
    const { page = 1, pageSize = 10 } = this.state;
    return data.map((o, index) => (
      {
        ...o,
        num: (page - 1) * pageSize + (index + 1),
        key: o.id ? o.id.toString() : Math.random().toString(36),
      }
    ));
  };

  handleTableChange = (pagination) => {
    const { params } = this.props;

    this.setState({
      page: pagination.current,
      pageSize: pagination.pageSize,
    }, () => {
      this.props.getMethod({
        ...params,
        page: pagination.current,
        offset: pagination.current - 1,
        pageSize: pagination.pageSize,
      });

      { return this.props.setOffset ? this.props.setOffset(this.state.page) : undefined }
    });
  }

  reset = () => {
    this.setState(defaultValues);
    this.props.getMethod(defaultValues);
  }

  rowSelection() {
    return {
      onChange: (selectedRowKeys) => {
        const { page } = this.state;
        const { onSelectRow } = this.props;
        onSelectRow(selectedRowKeys, page);
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === 'Disabled User',
        name: record.name,
      }),
    };
  }

  render() {
    const {
      columns,
      loading,
      selectable,
      selectedRowKeys,
      showSizeChanger,
      pageSizeOptions,
      position,
      disablePagination,
      hideSelectAll,
    } = this.props;

    const {
      total,
      page,
      pageSize,
      data,

    } = this.state;

    return (
      <div className="dataTable__wrapper">
        {selectable ? (
          <Table
            rowSelection={{
              type: 'checkbox',
              ...this.rowSelection(),
              selectedRowKeys,
              hideSelectAll,
            }}
            dataSource={data && this.getRows(data.rows)}
            columns={this.getColumns(columns)}
            bordered={false}
            loading={loading}
            pagination={!disablePagination && {
              current: page,
              total,
              page,
              pageSize,
              showSizeChanger,
              pageSizeOptions,
            }}
            size="middle"
            onChange={(ev) => this.handleTableChange(ev)}
            locale={{
              emptyText: I18n.t('shared.nothingToShow'),
            }}
            footer={() => `Total: ${total}`}
          />
        ) : (
          <Table
            dataSource={data && this.getRows(data.rows)}
            columns={this.getColumns(columns)}
            bordered={false}
            loading={loading}
            pagination={!disablePagination && {
              current: page,
              total,
              page,
              pageSize,
              showSizeChanger,
              pageSizeOptions,
              position,
            }}
            size="middle"
            onChange={(ev) => this.handleTableChange(ev)}
            locale={{
              emptyText: I18n.t('shared.nothingToShow'),
            }}
            footer={() => `Total: ${total}`}
          />
        )}
      </div>
    );
  }
}

export default DataTable;
