import Immutable from 'seamless-immutable';
import {
  ACTION_SAVE_QUIZ_STATS,
  ACTION_SAVE_STATISTIC_DETAILS,
  ACTION_SAVE_STATISTIC_SELECTIVES,
  ACTION_SAVE_STATISTICS_DASHBOARD,
  ACTION_SAVE_STATISTICS_PAGINATED,
  ACTION_SAVE_STATISTICS_USER,
  ACTION_SAVE_STUDENTS_PROGRESS,
  ACTION_SAVE_USER_FOR_STATISTICS,
} from '../actions/statistic';

const initialState = Immutable({
  statisticsPaginated: null,
  statisticsDashboard: null,
  statisticsUser: null,
  statisticDetails: null,
  statisticSelectives: null,
  statisticsKanban: null,
  statisticsUsersList: null,
  quizStats: null,
  studentProgress: null
});

export default function statistic (
  state = initialState,
  action,
) {
  switch (action.type) {
    case ACTION_SAVE_STATISTICS_DASHBOARD:
      state = {
        ...state,
        statisticsDashboard: action.payload,
      };
      return state;
    case ACTION_SAVE_STATISTICS_USER:
      state = {
        ...state,
        statisticsUser: action.payload,
      };
      return state;
    case ACTION_SAVE_STATISTICS_PAGINATED:
      state = {
        ...state,
        statisticsPaginated: action.payload,
      };
      return state;
    case ACTION_SAVE_STATISTIC_DETAILS:
      state = {
        ...state,
        statisticDetails: action.payload,
      };
      return state;
    case ACTION_SAVE_STATISTIC_SELECTIVES:
      state = {
        ...state,
        statisticSelectives: action.payload,
      };
      return state;
    case ACTION_SAVE_USER_FOR_STATISTICS:
      state = {
        ...state,
        statisticsUsersList: action.payload,
      };
      return state;
    case ACTION_SAVE_QUIZ_STATS:
      state = {
        ...state,
        quizStats: action.payload,
      };
      return state;
    case ACTION_SAVE_STUDENTS_PROGRESS:
      state = {
        ...state,
        studentProgress: action.payload,
      };
      return state;
    default:
      return state;
  }
}

export function getStatisticsPaginated (state) {
  return state.statistic.statisticsPaginated;
}

export function getStatisticsDashboard (state) {
  return state.statistic.statisticsDashboard;
}

export function getStatisticsUser (state) {
  return state.statistic.statisticsUser;
}

export function getStatisticDetails (state) {
  return state.statistic.statisticDetails;
}

export function getStatisticsKanban (state) {
  return state.statistic.statisticsKanban;
}

export function getStatisticSelectives (state) {
  return state.statistic.statisticSelectives;
}

export function getUserForStatistics (state) {
  return state.statistic.statisticsUsersList;
}

export function getQuizStats (state) {
  return state.statistic.quizStats;
}

export function getStudentProgress (state) {
  return state.statistic.studentProgress;
}

