import React, { useEffect, useState } from 'react';
import { I18n } from 'react-redux-i18n';
import { connect, useDispatch } from 'react-redux';
import TrailsApi from '../../../../app/api/trails';
import { CategoryActions, CompanyActions, TrailsActions } from '../../../../app/redux/actions';
import {
  LoadingSelectors,
  TrailsSelectores,
} from '../../../../app/redux/reducers';
import TrailForm from './TrailForm/TrailForm';
import { message } from 'antd';
import { navigate } from '@reach/router';
import moment from 'moment-timezone';
import { addLoading, removeLoading } from '../../../../app/redux/actions/loading';

export const UpdateTrailComponent = ({ id,  getTrailDetail, getUsersProgressInTrail, trailDetail }) => {
  const referenceTypeEnum = {
    EXTERNAL: 1,
    VIDEO: 2,
    AUDIO: 3,
    RESUME: 4,
    QUESTION: 5,
    QUIZ: 6,
    KNOWLEDGE_PREVIEW: 7,
    VIDEO_QUESTION: 8,
  };

  const referenceTypeToTextEnum = {
    1: 'EXTERNAL',
    2: 'VIDEO',
    3: 'AUDIO',
    4: 'RESUME',
    5: 'QUESTION',
    6: 'QUIZ',
    7: 'KNOWLEDGE_PREVIEW',
    8: 'VIDEO_QUESTION',
  };

  const [ selectedCompanyId, setSelectedCompanyId ] = useState(null);
  const dispatch = useDispatch();

  const [ trailFields, setTrailFields ] = useState({
    trailType: 2,
    contents: [],
    companyId: null,
    description: '',
    title: '',
  });

  const [ isMounted, setIsMounted ] = useState(false);
  const [ hasPendingDataToSave, setHasPendingDataToSave ] = useState(false);

  useEffect(() => {
    if (id) {
      getTrailDetail(id);
      getUsersProgressInTrail(id);
    }
  }, [ id, getTrailDetail, getUsersProgressInTrail ]);

  useEffect(() => {
    if (trailDetail) {
      let transformedContents = [];

      if (trailDetail.contents) {
        transformedContents = trailDetail.contents.map((content) => ({
          id: content.id || null,
          available: content.available,
          order: content.order,
          referenceType: content.referenceType,
          referenceId: content.referenceId,
          thumbnailUrl: content.thumbnailUrl,
          title: content.title,
          url: content.url,
          type: referenceTypeEnum[content.referenceType],
          content: {
            type: referenceTypeEnum[content.referenceType],
          },
          newContent: null,
        }));
      }

      setTrailFields({
        trailType: trailDetail.type,
        contents: trailDetail.contents ? transformedContents : [],
        companyId: trailDetail.company?.id,
        description: trailDetail.description,
        company: trailDetail.company,
        users: trailDetail.users,
        title: trailDetail.title,
        isDraft: trailDetail.isDraft,
        isEnabled: trailDetail.isEnabled,
        imageUrl: trailDetail.imageUrl,
        id: trailDetail.id,
        startAt: trailDetail?.startAt ? moment(trailDetail.startAt) : undefined
      });
    }
  }, [ trailDetail ]);

  useEffect(() => {
    if (!isMounted) {
      dispatch(CompanyActions.getCompanySelectives());
      dispatch(CategoryActions.getCategorySelectives());

      setIsMounted(true);
      return;
    }

    setHasPendingDataToSave(true);
  }, [ dispatch, isMounted, trailFields ]);

  const handleFieldChange = (fieldKey, fieldValue) => {
    setTrailFields((prevFields) => ({
      ...prevFields,
      [fieldKey]: fieldValue,
    }));
  };

  const validatingForm = (form) => {
    if (!form.title) {
      message.error(I18n.t('routes.panel.knowledgeTrails.create.error.title'));
      return false;
    }

    if (!form.coverUrl) {
      message.error(I18n.t('routes.panel.knowledgeTrails.create.error.coverUrl'));
      return false;
    }

    if (!form.description) {
      message.error(I18n.t('routes.panel.knowledgeTrails.create.error.description'));
      return false;
    }

    if (!form.companyId) {
      message.error(I18n.t('routes.panel.knowledgeTrails.create.error.companyId'));
      return false;
    }

    if (form.steps.length === 0) {
      message.error(I18n.t('routes.panel.knowledgeTrails.create.error.steps'));
      return false;
    }

    const isInvalidContent = form.steps.find(step => step.referenceType !== 'EXTERNAL' && !step.referenceId)
    const isInvalidYouTube = form.steps.find(step => step.referenceType === 'EXTERNAL' && !step.url)

    if (isInvalidContent || isInvalidYouTube) {
      message.error(I18n.t('routes.panel.knowledgeTrails.create.error.incompleteSteps'));
      return false;
    }

    if (form.students.length === 0) {
      message.error(I18n.t('routes.panel.knowledgeTrails.create.error.students'));
      return false;
    }

    return true;
  };

  const validatingFormToDraft = (form) => {
    const isInvalidContent = form.steps.find(step => step.referenceType !== 'EXTERNAL' && !step.referenceId)
    const isInvalidYouTube = form.steps.find(step => step.referenceType === 'EXTERNAL' && !step.url)

    if (isInvalidContent || isInvalidYouTube) {
      message.error(I18n.t('routes.panel.knowledgeTrails.create.error.incompleteSteps'));
      return false;
    }

    return true;
  };

  const handleConclude = async (isDraft) => {
    dispatch(addLoading());
    let companyId = null
    let students = []

    if (selectedCompanyId === null && trailFields?.companyId) {
      companyId = trailFields.companyId
    } else if (selectedCompanyId) {
      companyId = selectedCompanyId
    }

    if (trailFields.students?.length > 0) {
      students = trailFields.students.map((student) => ({
        id: student.id,
      }))
    } else if (trailFields?.users?.length > 0) {
      students = trailFields.users?.map((student) => ({
        id: student.id,
      }))
    }

    const dataToSave = {
      trailType: 2,
      title: trailFields.title || '',
      companyId,
      description: trailFields.description || '',
      isEnabled: false,
      isDraft,
      coverUrl: trailFields?.iconImage || trailFields?.imageUrl || '',
      startAt: trailFields.startAt ? trailFields.startAt : undefined,
      steps: trailFields?.contents ? trailFields.contents.map((item, index) => ({
        isAvailable: false,
        id: item?.id || undefined,
        referenceType: item?.referenceType || referenceTypeToTextEnum[ item.content?.type ] || item.content?.type,
        referenceId: item?.referenceId || item.content.id,
        order: index + 1,
        title: item?.title || item.content?.title,
        url: item?.url || item.content?.url,
        thumbnail_url: item?.thumbnailUrl || item.content?.thumbnailUrl,
      })) : [],
      students
    };

    let isValid = false

    if (isDraft) {
      isValid = validatingFormToDraft(dataToSave)
    } else {
      isValid = validatingForm(dataToSave)
    }

    try {
      if (isValid) {
        await TrailsApi.update(trailFields.id, dataToSave);
        message.success(I18n.t('routes.panel.knowledgeTrails.update.success'));
        navigate(I18n.t('routes.panel.knowledgeTrails.url'));
      }
    } catch (error) {
      message.error(I18n.t('routes.panel.knowledgeTrails.update.error'));
    } finally {
      dispatch(removeLoading());
    }
  };

  const saveButton = {
    canSave: hasPendingDataToSave,
    label: I18n.t('shared.saveDraft'),
    canConclude: true,
    onSave: () => handleConclude(true),
  };

  return (
    <TrailForm
      saveButton={saveButton}
      handleFieldChange={handleFieldChange}
      trailFields={trailFields}
      handleConclude={() => handleConclude(false)}
      routeTitle={I18n.t('routes.panel.knowledgeTrails.update.pageTitle')}
      setSelectedCompanyId={setSelectedCompanyId}
      selectedCompanyId={selectedCompanyId}
    />
  );
};

const mapStateToProps = (state) => ({
  trailDetail: TrailsSelectores.getTrailDetail(state),
});

const mapDispatchToProps = (dispatch) => ({
  getTrailDetail: (id) => dispatch(TrailsActions.getTrailDetails(id)),
  getUsersProgressInTrail: (id) => dispatch(TrailsActions.getUsersProgressInTrail(id)),
});

export const UpdateTrail = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpdateTrailComponent);
