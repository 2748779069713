import React from 'react';
import { Translate } from 'react-redux-i18n';

export const QuestionType = {
  MULTIPLEANSWER: 1,
  DISSERTATION: 2,
  OTHER: 3,
  IMAGE: 4,
  VIDEO: 5,
  AUDIO: 6,
};

export const QuizType = {
  SIMULATION: 1,
  TEST: 2,
};

export const QuestionAnswerType = {
  MULTIPLE_ANSWER: 1,
  PHOTO: 2,
  AUDIO: 3,
  VIDEO: 4,
  TEXT: 5,
  DISSERTATION: 6,
};

export const ALTERNATIVE_TYPE = {
  PHOTO: 1,
  AUDIO: 2,
  VIDEO: 3,
  TEXT: 4,
  NONE: 5,
};

export const DifficultyType = {
  EASY: 1,
  MEDIUM: 2,
  HARD: 3,
};

export const Alternative_Answer = {
  MULTIPLEANSWER: 1,
  DISSERTATION: 2,
  OTHER: 3,
};

export const CHAT_TYPE = {
  MESSAGE: 1,
  CONTENT: 2,
  QUESTION_DATABASE: 3,
};

export const EnableType = {
  ENABLE: 2,
  DESABLE: 1,
};

export const shareType = {
  GROUP: 2,
  INDIVIDUAL: 1,
};

export const UserQuizStatus = {
  NOT_DONE: 1,
  PENDING: 2,
  DONE: 3,
  WAITING: 4,
};

export const CorrectionAnswerType = {
  WITHOUT_CORRECTION: 1,
  CORRECT: 2,
  WRONG: 3,
};

export const CorrectionAnnotationType = {
  TEXT: 1,
  IMAGE: 2,
  VIDEO: 3,
  AUDIO: 4,
};

export const QuestionTypeArray = [
  {
    id: QuestionType.MULTIPLEANSWER,
    name: <Translate value='forms.newQuestion.labelQuestionMultipleAnswer' />,
  },
];

export const DifficultyTypeArray = [
  {
    id: DifficultyType.EASY,
    name: <Translate value='forms.newQuestion.labelQuestionEasy' />,
  },
  {
    id: DifficultyType.MEDIUM,
    name: <Translate value='forms.newQuestion.labelQuestionMedium' />,
  },
  {
    id: DifficultyType.HARD,
    name: <Translate value='forms.newQuestion.labelQuestionHard' />,
  },
];

export const HospitalType = {
  PUBLIC: 1,
  PRIVATE: 2,
  SEMI_PUBLIC: 3,
};

export const isEnableEnum = [
  { id: EnableType.ENABLE, name: 'Disponível' },
  { id: EnableType.DESABLE, name: 'Indisponível' },
];

export const justificationsOption = [
  { id: 'false', name: <Translate value='shared.advancedFilters.justifications.no' /> },
  { id: 'true', name: <Translate value='shared.advancedFilters.justifications.yes' /> },
];

export const shareTypeEnum = [
  { id: shareType.GROUP, name: 'Grupo' },
  { id: shareType.INDIVIDUAL, name: 'Individual' },
];

export const QuizTypeEnum = [
  { id: QuizType.SIMULATION, name: 'Simulado' },
  { id: QuizType.TEST, name: 'Prova Livre' },
];

export const CorrectionAnswerArray = [
  { id: CorrectionAnswerType.WITHOUT_CORRECTION, name: 'Sem correção' },
  { id: CorrectionAnswerType.CORRECT, name: 'Correto' },
  { id: CorrectionAnswerType.WRONG, name: 'Incorreto' },
];

export const UserQuizStatusArray = [
  { id: UserQuizStatus.NOT_DONE, name: 'Não realizada' },
  { id: UserQuizStatus.PENDING, name: 'Pendente' },
  { id: UserQuizStatus.DONE, name: 'Realizada' },
  { id: UserQuizStatus.WAITING, name: 'Aguardando correção' },
];

export const arrayPercentage = [
  { id: 0, name: '0%' },
  { id: 10, name: '0% a 10%' },
  { id: 20, name: '10% a 20%' },
  { id: 30, name: '20% a 30%' },
  { id: 40, name: '30% a 40%' },
  { id: 50, name: '40% a 50%' },
  { id: 60, name: '50% a 60%' },
  { id: 70, name: '60% a 70%' },
  { id: 80, name: '70% a 80%' },
  { id: 90, name: '80% a 90%' },
  { id: 100, name: '90% a 100%' },
];
